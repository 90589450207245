@import '../../StyleHelpers/Variables.scss';

.content_wrapper {
    width: 100%;

    .container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .main_row {
            width: 100%;
            display: block;

            form {
                display: flex;
                gap: 20px;

                @include breakpoint(md) {
                    flex-direction: column;
                }

                @include mediumScreen {
                    flex-direction: column;
                }

                .left {
                    flex: 1;
                    border-right: 2px solid $base_color;

                    @include breakpoint(md) {
                        border-bottom: 2px solid $base_color;
                        border-right: none;
                    }

                    @include mediumScreen {
                        border-bottom: 2px solid $base_color;
                        border-right: none;
                    }
                }

                .right {
                    flex: 3;
                }

                .left,
                .right {
                    display: flex;
                    flex-direction: column;
                    padding: 10px 40px;

                    @include breakpoint(md) {
                        padding: .5rem;
                    }

                    .title {
                        margin-bottom: 1rem;

                        h2 {
                            margin-bottom: .5rem;
                            font-size: 1.4rem;
                            font-weight: 500;
                            line-height: 1;
                        }
                    }

                    .notificationDiv {
                        padding: 12px 20px;
                        margin-bottom: 1.5rem;
                        border-radius: 5px;
                        background: $primary_color;

                        p {
                            color: $white_color;
                            font-size: 14px;
                            line-height: 1.5;
                        }
                    }

                    .row {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 1rem;

                        .left_col {
                            width: 100%;
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .Image_info {
                                width: 120px;
                                height: 120px;
                                margin: 1rem 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    object-fit: cover;
                                    position: relative;
                                }

                                .formImage {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 2rem;
                                    height: 2rem;
                                    border-radius: 50%;
                                    background-color: $primary_color;
                                    position: absolute;
                                    right: -5px;
                                    bottom: 1rem;

                                    label {
                                        color: #fff;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        i {
                                            display: flex;
                                            align-items: center;
                                        }
                                    }
                                }

                            }
                        }

                        .items {
                            width: 100%;

                            .item {
                                background-color: #fff;
                                padding: 1rem .6rem;
                                margin: 1rem 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 5px;
                                border: 1px solid $base_color;
                                cursor: pointer;

                                span {
                                    font-size: .8rem;
                                    font-weight: 500;
                                    line-height: 1.5;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 10px;

                                    i {
                                        display: flex;
                                        align-items: center;
                                        color: $primary_color;
                                        font-size: 1rem;
                                    }

                                    .del_icon {
                                        color: red;
                                    }
                                }
                            }
                        }
                    }

                    // -- form_wrapper
                    .form_wrapper {
                        display: flex;
                        flex-direction: column;

                        // -- InputField styling
                        input {
                            height: 40px;
                            padding: 0.4rem 1rem;
                            font-size: 14px;
                            line-height: 1;
                            font-weight: 300;
                            background-color: #fff;
                            border: 1px solid $base_color;
                            border-radius: 5px;
                            transition: all 0.3s;
                            box-shadow: none;
                            outline: none;
                        }

                        // -- Button Styling
                        button {
                            border: none;
                            height: 100%;
                            width: 100%;
                            font-size: 16px;
                            font-weight: 600;
                            color: white;
                            outline: none;
                            border-radius: 5px;
                            cursor: pointer;
                        }

                        // -- Error style
                        .error {
                            color: red;
                            font-size: 14px;

                            @include breakpoint(md) {
                                font-size: 12px;
                            }
                        }

                        span {
                            color: red;
                        }

                        .row {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: .5rem;
                        }



                        .flex_row {
                            display: flex;
                            gap: 10px;
                            margin-bottom: .5rem;

                            @include breakpoint(md) {
                                flex-direction: column;
                            }

                            @include mediumScreen {
                                flex-direction: column;
                            }
                        }

                        .button_row {
                            display: flex;
                            justify-content: flex-end;
                            gap: 10px;
                            margin: 10px 0;

                            .cancelBtn {
                                button {
                                    padding: 10px 20px;
                                    background-color: #fff;
                                    color: $primary_color;
                                    border: 2px solid $primary_color;
                                }
                            }

                            .submitBtn {
                                button {
                                    padding: 10px 40px;
                                    background-color: $primary_color;
                                    color: #fff;
                                }
                            }
                        }

                        .row,
                        .flex_row,
                        .border_row {
                            .col {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                label {
                                    @include breakpoint(md) {
                                        font-size: 14px;
                                    }

                                    @include mediumScreen {
                                        font-size: 16px;
                                    }
                                }

                                .dobInput {
                                    width: 100%;
                                    height: 40px;
                                    padding: 0.4rem 0;
                                    font-size: 14px;
                                    line-height: 1;
                                    font-weight: 300;
                                    background-color: #fff;
                                    border: 1px solid $base_color;
                                    border-radius: 5px;
                                    transition: all 0.3s;
                                    box-shadow: none;
                                    outline: none;
                                }

                                .border_col {
                                    display: flex;
                                    gap: 10px;
                                    margin-bottom: .5rem;
                                    border: 1px solid $base_color;
                                    border-radius: 5px;
                                    background: #fff;

                                    .borderless {
                                        border: none;
                                        flex: 2;
                                    }

                                    .check {
                                        flex: 1;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        span {
                                            color: $green_color;
                                        }
                                    }
                                }

                                .flex_col {
                                    width: 100%;
                                    display: flex;
                                    gap: 10px;

                                    .form_Input {
                                        flex: 2;
                                    }

                                    .form_button {
                                        display: flex;
                                        flex: 1;
                                        justify-content: center;
                                        align-items: center;

                                        button {
                                            background-color: $green_color;
                                        }
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }
    }
}