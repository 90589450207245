@import '../../../StyleHelpers/Variables.scss';

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .main {
        width: 100%;
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            margin-top: 120px;
        }

        @include mediumScreen {
            margin-top: 120px;
        }

        @include LargeScreen {
            margin-top: 80px;
        }

        .Wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: $base_color3;
            padding: 2rem 0;

            .productwrapper {
                width: 100%;
                min-height: 35rem;
                background-color: #f8f8f8;

                .container {
                    margin: 0 3rem;
                    display: block;
                    padding: 0 20px;

                    @include breakpoint(md) {
                        margin: 0 0;
                        padding: 0 10px;
                    }

                    @include mediumScreen {
                        margin: 0 1rem;
                    }

                    .heading {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 2rem;
                        padding: .5rem 0;
                        border-bottom: 2px solid #ebebeb;

                        .left {
                            h2 {
                                color: $base-color5;
                                font-weight: 600;
                            }
                        }
                    }

                    .cardWrapper {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        place-items: center;
                        place-content: center;
                        gap: 20px;


                        @include breakpoint(md) {
                            grid-template-columns: 1fr 1fr;
                            gap: 10px;
                        }

                        @include mediumScreen {
                            grid-template-columns: 1fr 1fr 1fr;
                        }

                        @include extraMediumScreen {
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                        }

                        @include LargeScreen {
                            grid-template-columns: 1fr 1fr 1fr;
                        }
                    }
                }
            }

            .loadbtn {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                gap: 20px;
                padding: 3rem 0 1rem 0;

                .inactiveBtn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: $base_color5;
                    background-color: transparent;
                    padding: 10px;
                    font-size: 1.2rem;
                    font-weight: 700;
                    line-height: 1.2;
                    border-radius: 5px;
                    border: none;
                    cursor: pointer;

                    &:hover {
                        color: $base_color5;
                    }
                }

                .activeBtn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: $base_color5;
                    background-color: transparent;
                    padding: 10px;
                    font-size: 1.2rem;
                    font-weight: 700;
                    line-height: 1.2;
                    border-radius: 5px;
                    border: none;
                    cursor: pointer;

                    &:hover {
                        color: $primary_color;
                    }

                    .icon {
                        font-size: 20px;
                        font-weight: 900;
                    }
                }
            }
        }
    }
}