@import '../../../StyleHelpers/Variables.scss';


.Header {
    height: 70px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-bottom: 2px solid $base_color3;

    //position: fixed;
    //z-index: 999;
    //width: 80%;
    //background-color: #fff;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 40px;
        gap: 10px;
        
        .search{
            display: flex;
            align-items: center;
            width: 300px;
            border: 1px solid $base_color4;
            border-radius: 5px;
            padding: 5px;

            input{
                width: 100%;
                height: 2rem;
                padding: 0 5px;
                background: transparent;
                border: none;
                outline: none;

                &::placeholder{
                    font-size: 12px;
                }
            }
        }

        .items {
            display: flex;
            align-items: center;
            gap: 10px;
           
            .item{
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;

                .icon{
                    font-size: 24px;
                }

                .avatar{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                .counter{
                    width: 15px;
                    height: 15px;
                    background-color: red;
                    border-radius: 50%;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                }
            }
        }
    }
}