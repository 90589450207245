.productwrapper {
  width: 100%;
  background-color: #f8f8f8;
}
@media (max-width: 550px) {
  .productwrapper {
    display: none;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .productwrapper {
    display: none;
  }
}
.productwrapper .container {
  margin: 4rem 4rem;
  display: block;
  padding: 0 20px;
}
@media (max-width: 550px) {
  .productwrapper .container {
    margin: 0 0;
    padding: 0 10px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .productwrapper .container {
    margin: 0 1rem;
  }
}
.productwrapper .container .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 1rem 0;
  border-bottom: 2px solid #ebebeb;
}
.productwrapper .container .heading .left h2 {
  color: #666;
  font-weight: 600;
}
@media (max-width: 550px) {
  .productwrapper .container .heading .left h2 {
    font-size: 16px;
  }
}
.productwrapper .container .heading .right span {
  cursor: pointer;
  color: #046BD2;
}
@media (max-width: 550px) {
  .productwrapper .container .heading .right span {
    font-size: 12px;
  }
}
.productwrapper .container .cardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  place-items: center;
  place-content: center;
  gap: 20px;
}
@media (max-width: 550px) {
  .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr;
    gap: 10px 20px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}/*# sourceMappingURL=index.module.css.map */