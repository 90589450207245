@import '../../../StyleHelpers/Variables.scss';

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .main {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            margin-top: 120px;
        }

        @include mediumScreen {
            margin-top: 120px;
        }

        @include LargeScreen {
            margin-top: 80px;
        }

    }
}