.datatable {
  height: 500px;
  padding: 20px;
}
.datatable .datatableTitle {
  width: 100%;
  font-size: 24px;
  color: gray;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.datatable .datatableTitle .link {
  text-decoration: none;
  color: green;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.datatable .datagrid {
  border: 1px solid lightgrey;
  border-radius: 5px;
  min-height: 460px;
}
.datatable .cellWithImg {
  display: flex;
  align-items: center;
}
.datatable .cellWithImg .cellImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 20px;
}
.datatable .cellWithStatus {
  padding: 5px;
  border-radius: 5px;
}
.datatable .cellWithStatus.active {
  background-color: rgba(0, 128, 0, 0.05);
  color: green;
}
.datatable .cellWithStatus.pending {
  background-color: rgba(255, 217, 0, 0.05);
  color: goldenrod;
}
.datatable .cellWithStatus.passive {
  background-color: rgba(255, 0, 0, 0.05);
  color: crimson;
}/*# sourceMappingURL=Style.module.css.map */