@import '../../../StyleHelpers/Variables.scss';  // Assuming Variables.scss contains global styles like colors, fonts, etc.

.list {
    display: flex;
    flex: 2;

    .listContainer {
        flex: 6;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
    }

    .cellAction {
        display: flex;
        align-items: center;
        gap: 12px; // Increased gap for better spacing between buttons
    }

    // General button styling
    .viewButton, .editButton, .deleteButton {
        padding: 10px 15px;  // Padding for a more comfortable button size
        border-radius: 5px;
        text-align: center;
        background-color: #fff;
        cursor: pointer;
        font-weight: bold;
        transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;  // Smooth transition for hover effects

        &:hover {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);  // Subtle shadow effect on hover for better UX
        }
    }

    .viewButton {
        color: green;
        border: 1px solid green;

        &:hover {
            background-color: green;
            color: #fff;
        }
    }

    .editButton {
        color: darkblue;
        border: 1px solid rgba(0, 0, 139, 0.596);

        &:hover {
            background-color: darkblue;
            color: #fff;
        }
    }

    .deleteButton {
        color: crimson;
        border: 1px solid rgba(220, 20, 60, 0.6);

        &:hover {
            background-color: crimson;
            color: #fff;
        }
    }

    .SquareImage {
        margin: 5px;
        object-fit: cover;
        width: 50px;  // Added fixed width and height for consistent image sizes
        height: 50px;
    }

    .RoundedImage {
        border-radius: 100%;
        margin: 5px;
        object-fit: cover;
        width: 50px;
        height: 50px;
    }

    .optionsContainer {
        display: flex;
        gap: 12px;  // Consistent gap between buttons inside the container
        justify-content: center;  // Center the buttons horizontally
        align-items: center;  // Center the buttons vertically
    }

    // Making the button styles more consistent
    .viewButton, .editButton, .deleteButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;  // Ensure minimum button size for better touch-target size
        height: 40px;  // Consistent button height
    }

    // Buttons inside SweetAlert modal
    .confirmButton {
        padding: 10px 20px;
        background-color: green;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: darkgreen;
        }
    }

    .cancelButton {
        padding: 10px 20px;
        background-color: crimson;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: darkred;
        }
    }
    .link {
        text-decoration: none;
        color: green;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid green;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        height: 27px;
    }
    .delete {
        text-decoration: none;
        color: rgb(237, 79, 79);
        font-size: 16px;
        font-weight: 400;
        border: 1px solid red;
        padding: 15px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px 5px;
        cursor: pointer;
        height: 27px;
       
    }
    .title{
        width: 100%;
        font-size: 24px; 
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    
}
