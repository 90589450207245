.productwrapper {
  width: 100%;
  min-height: 300px;
  background-color: #f8f8f8;
}
.productwrapper .container {
  margin: 0 2rem;
  display: block;
}
@media (max-width: 550px) {
  .productwrapper .container {
    margin: 0 0;
    padding: 0 10px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .productwrapper .container {
    margin: 0 1rem;
  }
}
.productwrapper .container .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0.5rem 0;
  border-bottom: 2px solid #ebebeb;
}
.productwrapper .container .heading .left h2 {
  color: #666;
  font-size: 22px;
  font-weight: 600;
}
.productwrapper .container .cardWrapper {
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
@media (max-width: 550px) {
  .productwrapper .container .cardWrapper {
    flex: 1 1 50%;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    margin-inline: auto;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}/*# sourceMappingURL=index.module.css.map */