@import '../../StyleHelpers/Variables.scss';

//------- CatergoryFilter Styling --------

.Container {
    display: block;
    min-height: 100%;
 

    .icon {
        font-size: 14px;
        font-weight: 900;
    }

    .top {
        margin-bottom: .5rem;

        .filterDiv {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem;
            background: #fff;

            .headingDiv {
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .clearDiv {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                color: $primary_color;
                cursor: pointer;

                span {
                    font-size: 14px;
                    font-weight: 600;
                    text-align: center;
                }

                .icon {
                    font-size: 14px;
                    font-weight: 900;
                }
            }
        }

        .selectedFilterDiv {
            margin-bottom: .5rem;
            background: #fff;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            padding: 1rem;

            .wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid $base-color3;
                min-width: 70px;
                width: fit-content;
                border-radius: 25px;
                gap: 10px;
                padding: 5px;
                font-size: 14px;

                span {
                    cursor: pointer;
                }
            }
        }
    }

    .bottom {
        .accordion {
            display: flex;
            flex-direction: column;
            // gap: 10px;

            .titleDiv {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #fff;   
                padding: .5rem;

                h3 {
                    font-size: 16px;
                    font-weight: 500;
                }

                span {
                    display: flex;
                    align-items: center;
                }
            }

            .content {
                background-color: white;
                overflow-y: hidden;
                max-height: 0;
                transition: all 0.5s cubic-bezier(0, 1, 0, 1);
                display: none; 
                
            }

            .show {
                background-color: white;
                display: flex;
                align-items: center;
                margin: 2px 0;
                padding: .8rem;

                @include breakpoint(md) {

                    display: flex;
                    align-items: center;
                }

                @include mediumScreen {

                    display: flex;
                    align-items: center;
                }


                .items {
                    width: 100%;
                    min-height: 3rem;
                    max-height: 12rem;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;
                    color: #111;
                    background-color: #ffffff;
                    overflow-y: auto;
                    cursor: pointer;
                   
                    .items_wrapper {
                        width: 100%;
                        p {
                            margin: .4rem .6rem;

                        }

                        &:hover {
                            background-color: $base-color;
                            border-radius: 5px;
                            color: #111;
                        }

                        &:active,
                        :focus {
                            background-color: $base-color;
                            border-radius: 5px;
                            color: #111;
                        }
                    }


                    .radioField_wrapper {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        width: 100%;
                        padding: 4px 0;
                        cursor: pointer;

                    }

                   
                        

                }

                .onSelectField {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 1rem;
                    gap: 20px;
                    cursor: pointer;

                    .items_wrapper {
                        width: 100%;

                        .item_Title {
                            padding-bottom: 2px;

                            h3 {
                                font-size: 15px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .inputContent {
                background: #fff;
                padding: 0.5rem 1rem;
                margin: 2px 0;

                .inputFields {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin-bottom: .8rem;

                    .field_wrapper {
                        width: 100%;
                        height: 40px;
                        display: flex;
                        align-items: center;

                        input {
                            width: 100%;
                            height: 100%;
                            outline: none;
                            border-radius: 5px;
                            font-size: 15px;
                            text-align: center;
                            border: 1px solid #ccc;
                            --moz-appearance: none;
                        }

                        input[type="number"] {
                            -moz-appearance: textfield;
                            appearance: textfield;

                            &::-webkit-inner-spin-button,
                            &::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                        }
                    }

                    .seperation {
                        width: 80px;
                        font-size: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    //Input Field Search Button Styling
                    .field_searchBtn {
                        width: 80px;
                        height: 40px;

                        button {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: $primary_color;
                            color: #fff;
                            border: none;
                            outline: none;
                            border-radius: 4px;
                            cursor: pointer;
                        }
                    }
                }

                //Price Field Search Button Styling
                .searchBtn {
                    width: 100%;
                    height: 40px;

                    button {
                        width: 100%;
                        height: 100%;
                        background: $primary_color;
                        color: #fff;
                        border-radius: 4px;
                        font-size: 15px;
                        font-weight: 500;
                        outline: none;
                        border: none;
                        cursor: pointer;
                    }
                }

                //Radio Field Styling
                .radioFields {
                    min-height: 5rem;
                    max-height: 12rem;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;

                    .radioField_wrapper {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 4px 0;
                        width: 100%;
                        cursor: pointer;
                    }

                }

                .slider_wrapper {
                    padding: 1rem 0;
                    border-top: 2px solid $base-color3;

                    p {
                        margin-bottom: .8rem;
                        font-size: 14px;
                    }

                    .slider_label {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: .8rem;
                    }

                    .slider {
                        width: 100%;

                        .progress_wrap {
                            width: 100%;
                            height: 5px;
                            border-radius: 5px;
                            background: $base-color;
                            position: relative;

                            .progress {
                                height: 5px;
                                // background: $primary_color;
                                position: absolute;
                                left: 25%;
                                right: 25%;
                                border-radius: 5px;
                            }
                        }

                        .rangeInput {
                            position: relative;

                            input {
                                position: absolute;
                                top: -5px;
                                height: 5px;
                                width: 100%;
                                background: none;
                                pointer-events: none;
                                -webkit-appearance: none;
                                appearance: none;
                            }

                            input[type="range"] {
                                &::-webkit-slider-thumb {
                                    height: 17px;
                                    width: 17px;
                                    border-radius: 50%;
                                    background: $primary_color;
                                    pointer-events: auto;
                                    -webkit-appearance: none;
                                    appearance: none;
                                }
                            }

                            input[type="range"] {
                                &::-moz-range-thumb {
                                    height: 17px;
                                    width: 17px;
                                    border: none;
                                    border-radius: 50%;
                                    background: $primary_color;
                                    pointer-events: auto;
                                    -moz-appearance: none;
                                    background: $primary_color;
                                }
                            }
                        }
                    }

                    .range_searchBtn {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 2rem;
                        height: 35px;

                        button {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: $primary_color;
                            color: #fff;
                            border: none;
                            outline: none;
                            border-radius: 4px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

// ------------------End------------------