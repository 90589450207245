@import '../../../StyleHelpers/Variables.scss';

.box {
    padding: 0px 0;

    .img_Container {

        width: auto;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            object-fit: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }

}