@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat&family=Open+Sans:wght@300;400;500&family=Poppins:wght@200;300;400;500;600;700;900&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* @import "react-alice-carousel/lib/alice-carousel.css"; */

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

p {
  margin: 0px;
}

h1 {
  margin: 0px;
}

h2 {
  margin: 0px;
}

h3 {
  margin: 0px;
}

h5 {
  margin: 0px;
}

h6 {
  margin: 0px;
}

h4 {
  margin: 0px;
}

.star {
  color: red;
}

input:required {
  background-color: black;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c0c0c0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #046BD2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}