@import "../../../StyleHelpers/Variables.scss";

.modalOverlay{
    background-color: white;

.modalContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 750px;
  height: 450px;
  border-radius: 15px;
  padding: 20px 59px;
  position: relative;
  background-color: white;
//   background:linear-gradient(to bottom, #FFFAF0, #FFEBCD); 
  
  @include breakpoint(md) {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   max-width: 75vw;
   height: auto;
   max-height: 80vh;
   padding: 45px;
  }

  .closeIconWrapper {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 20px;  /* Correct positioning with px */
    right: 20px;  /* Correct positioning with px */
    
    .closeButton {
      background: transparent;
      border: none;
      font-weight: 100;
      font-size: 20px;
      cursor: pointer;
      color: #333;
      width: 10px;
    }
  }
  
  .refferimage {
    width: 450px;
    @include breakpoint(md){
        width: 220px;
    }

  }

  p {
    font-size: medium;
    font-size: 16px;
    @include breakpoint(md){
        font-size: 14px;
    }
  }

  .referralButton {
    background-color:$primary_color;
    color: white;
    padding: 12px 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    margin-inline: auto;
  
    @include breakpoint(md){
      display: flex;
      justify-content: center;
    }


    &:hover {
      background-color: red;
    }
  }
}
}

@import '../../../StyleHelpers/Variables.scss';



    .item_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
     

        @include breakpoint(md) {}

        .wrapper {
            width:700px;
            min-height: 500px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            border-radius: 10px;
            border: 3px solid $base-color4;
            margin: 10px;
            padding: 20px;

            @include breakpoint(md) {
                padding: 20px;
            }

            @include mediumScreen {
                padding: 20px;
            }


        
            .top {
                height:100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-bottom: 2px solid $base-color4;

                .row1 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 10px 0;

                    h3 {
                        font-size: 18px;
                        font-weight: 600;

                        @include breakpoint(md) {
                            font-size: 14px;
                        }

                        @include mediumScreen {}
                    }

                    h5 {
                        padding: 5px 10px;
                        background-color: $primary_color;
                        border-radius: 25px;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 300;

                        @include breakpoint(md) {
                            font-size: 10px;
                        }
                    }
                }

                .row2 {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 10px 0;
                    padding: 0 10px;

                    h1 {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        font-size: 38px;
                        color: $primary_color;

                        @include breakpoint(md) {
                            font-size: 28px;
                        }
                    }

                    span {
                        font-size: 38px;
                        font-weight: 900;
                        margin: 0 5px;

                        @include breakpoint(md) {
                            font-size: 28px;
                        }
                    }

                    h4 {
                        font-size: 12px;
                        color: $base-color2;

                        @include breakpoint(md) {
                            font-size: 10px;
                        }
                    }

                }

                .row3 {
                    padding: 5px 10px;

                    p {
                        font-size: 14px;
                        padding: 5px 10px;
                        border-radius: 25px;
                        background-color: $base-color3;
                        width: fit-content;
                    }
                }

                .row4 {
                    margin: 20px 0;

                    button {
                        width: 100%;
                        padding: 10px 0;
                        background: $gradient_color;
                        color: #fff;
                        border: none;
                        border-radius: 5px;
                        font-size: 16px;
                        font-weight: 200;
                        cursor: pointer;
                    }
                }
            }

            .bottom {
                min-height: 100px;
                display: flex;
                flex-direction: column;


                .title {
                    margin: 10px 0;

                    h2 {
                        font-size: 16px;

                    }
                }

                .row {
                    display: flex;
                    flex-direction: column;

                    .item {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        border-top: 1px solid $base-color4;
                        padding: 10px;

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $primary_color;
                            border: 2px solid $primary_color;
                            border-radius: 50%;
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }

            }



        }
    }


/*----- control btn -------*/
.control_btn {

    .next,
    .prev {
        position: absolute;
        top: 45%;
        width: 35px;
        height: 50px;
        line-height: 60px;
        background: #ffffff36;
        border-radius: 5px;
        border: none;
        transform: 0.5s;
        z-index: 5;


        i {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 50px;
            transition: 0.5s;
            color: #111;
            cursor: pointer;

        }

        &:hover {
            cursor: pointer;

            i {
                color: #111;
            }
        }
    }

    .prev {
        left: -20px;
    }

    .next {
        right: -20px;
    }
}

/*----- control btn -------*/


 


