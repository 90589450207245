@import '../../StyleHelpers/Variables.scss';


/*404 styling */
.pageWrapper {
    width: 100%;
    height: 100%;

    .container {
        min-height: 510px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Image_wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            img {
                width: 400px;
                height: 400px;
                object-fit: contain;

                @include breakpoint(md) {
                    width: 250px;
                    height: 250px;
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .nav{
                text-decoration: none;
                color: #fff;
            }

            h1{
                font-size: 24px;
                font-weight: 600;
                color: $base_color2;

                @include breakpoint(md) {
                    font-size: 20px;
                    font-weight: 500;
                }
            }

            button{
                background-color: $primary_color;
                color: #fff;
                padding: 15px;
                border-radius: 5px;
                border: none;
            }
        }
    }
}

/* End wishlistcard styling */