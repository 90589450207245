.page_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}
.page_wrapper .navigation {
  text-decoration: none;
  color: #111;
}
.page_wrapper .header_wrapper {
  width: 100%;
  height: 80px;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
}
.page_wrapper .header_wrapper .backarrow {
  padding: 0 20px;
  font-size: 24px;
  margin: 0 20px;
}
.page_wrapper .container_wrapper {
  height: 100%;
  min-height: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  margin-top: 80px;
  padding: 20px 80px;
  gap: 5px;
  overflow: hidden;
}
@media (max-width: 550px) {
  .page_wrapper .container_wrapper {
    display: none;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .container_wrapper {
    min-height: 480px;
    padding: 20px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .page_wrapper .container_wrapper {
    min-height: 510px;
  }
}
.page_wrapper .container_wrapper .container {
  display: flex;
  background-color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  gap: 5px;
}
.page_wrapper .container_wrapper .container .left_container {
  display: flex;
  flex-direction: column;
  min-width: 340px;
  min-height: 420px;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .container_wrapper .container .left_container {
    min-width: 250px;
  }
}
.page_wrapper .container_wrapper .container .left_container .box_title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2rem;
  padding: 5px 0;
  margin-bottom: 5px;
  background-color: #046BD2;
  color: #fff;
  border-radius: 5px;
}
.page_wrapper .container_wrapper .container .left_container .normal {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  padding: 5px 0;
  border-bottom: 2px solid #f8f8f8;
}
.page_wrapper .container_wrapper .container .left_container .normal:hover {
  background-color: #046BD2;
  color: #fff;
  border-radius: 5px;
}
.page_wrapper .container_wrapper .container .left_container .normal label {
  display: flex;
  align-items: flex-start;
  width: 80%;
  padding: 0 10px;
}
.page_wrapper .container_wrapper .container .left_container .normal span {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 20%;
  padding: 10px;
  font-size: 22px;
}
.page_wrapper .container_wrapper .container .left_container .hightlight {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  padding: 5px 0;
  border-bottom: 2px solid #f8f8f8;
  background-color: #046BD2;
  color: white;
  border-radius: 5px;
}
.page_wrapper .container_wrapper .container .left_container .hightlight:hover {
  background-color: #046BD2;
  color: #fff;
  border-radius: 5px;
}
.page_wrapper .container_wrapper .container .left_container .hightlight label {
  display: flex;
  align-items: flex-start;
  width: 80%;
  padding: 0 10px;
}
.page_wrapper .container_wrapper .container .left_container .hightlight span {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 20%;
  padding: 10px;
  font-size: 22px;
}
.page_wrapper .container_wrapper .container .right_container {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 420px;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .container_wrapper .container .right_container {
    min-width: 250px;
  }
}
.page_wrapper .container_wrapper .container .right_container .box {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  padding: 5px 0;
  border-bottom: 2px solid #f8f8f8;
}
.page_wrapper .container_wrapper .container .right_container .box:hover {
  background-color: #046BD2;
  color: #fff;
  border-radius: 5px;
}
.page_wrapper .container_wrapper .container .right_container .box label {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0 10px;
}
.page_wrapper .container_wrapper .container .right_container .box span {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 20%;
  padding: 10px;
  font-size: 22px;
}
.page_wrapper .M_container {
  display: none;
}
@media (max-width: 550px) {
  .page_wrapper .M_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    padding: 10px 20px;
    min-height: 510px;
    background-color: #f8f8f8;
    overflow: hidden;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .M_container {
    display: none;
  }
}
.page_wrapper .M_container .Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 420px;
  padding: 20px;
  background-color: #fff;
}
@media (max-width: 550px) {
  .page_wrapper .M_container .Container .left_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.page_wrapper .M_container .Container .left_container .box_title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2rem;
  padding: 5px 0;
  margin-bottom: 5px;
  background-color: #046BD2;
  color: #fff;
  border-radius: 5px;
}
.page_wrapper .M_container .Container .left_container .box_title h3 {
  font-size: 16px;
}
.page_wrapper .M_container .Container .left_container .box {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  padding: 5px 0;
  border-bottom: 2px solid #f8f8f8;
}
.page_wrapper .M_container .Container .left_container .box:hover {
  background-color: #046BD2;
  color: #fff;
}
.page_wrapper .M_container .Container .left_container .box label {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0 10px;
}
.page_wrapper .M_container .Container .left_container .box span {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 20%;
  padding: 10px;
  font-size: 22px;
}/*# sourceMappingURL=index.module.css.map */