@import '../../../../StyleHelpers/Variables.scss';





.Main_Container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 80px;
    background-color: $base-color3;
    padding: 40px;
    overflow: hidden;
    gap: 10px;


    *:required {
        background-color: rgb(250, 250, 250);
      }
      

    @include breakpoint(md) {
        padding: 10px;
    }

    @include LargeScreen {
        padding: 20px;
    }

    @include LargeScreen {}

    .header_wrapper {
        width: 100%;
        height: 80px;
        background-color: #fff;
        position: fixed;
        top: 0;
        z-index: 9;

        .backarrow {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 60px;
            font-size: 22px;
            height: 80px;

        }
    }



    /*--------Registeration form-------  */

    .Container_Wrapper {
        background-color: #fff;
        width: 60%;
        min-height: 480px;
        border-radius: 5px;
        padding: 60px;
        overflow: hidden;

        @include breakpoint(md) {
            width: 100%;
            padding: 20px;
        }

        @include mediumScreen {
            width: 70%;
        }

        @include LargeScreen {}

        .path {
            display: flex;
            gap: 10px;
            font-size: 15px;
            margin: 10px 0;
            padding: 20px 0;
            border-bottom: 1px solid grey;
        }

        form {
            display: flex;
            flex-direction: column;
            padding: 10px;

            .row {
                display: flex;
                flex-direction: column;
                margin: 10px 0;

                @include breakpoint(md) {}

                @include mediumScreen {}

                @include LargeScreen {}

                label {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 600;
                }

                .listeditems {
                    display: flex;
                    gap: 30px;
                    width: 100%;
                }

                .radioFieldItems {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px 20px;
                    width: 100%;

                    .radio {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        padding-top: 5px;

                        input {
                            display: flex;
                            flex-direction: row;
                            height: 1.5rem;
                            width: 20px;
                            margin-right: 10px;
                        }

                        .radioLabel {
                            font-size: 16px;
                            font-weight: 500;
                            margin-bottom: 0;
                        }
                    }
                }

                .items {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    @include breakpoint(md) {}

                    @include mediumScreen {}

                    @include LargeScreen {}
                }

                .items,
                .listeditems {
                    margin: 10px 0;

                    span {
                        margin-top: 10px;
                        font-size: 14px;
                        color: red;
                    }

                    p {
                        margin-top: 10px;
                        font-size: 12px;
                    }

                    .basic_single {
                        width: 100%;
                    }

                    //option select button 
                    button {
                        padding: 5px 15px;
                        margin: 5px;
                        line-height: 2;
                        border-radius: 4px;
                        border: 1px solid lightgrey;
                        background-color: #fff;
                        text-align: center;
                        font-size: 14px;

                        &:hover {
                            background-color: $primary-color;
                            color: #fff;
                            border: 1px solid $primary-color;
                        }
                    }

                    select {

                        height: 3rem;
                        border-radius: 4px;
                        border: 1px solid lightgrey;
                        outline: none;
                        padding: 0 20px;

                        &:hover {
                            border-color: $primary-color;
                            color: #111;
                        }

                        .selectoption {
                            font-size: 16px;
                            background-color: transparent;
                            padding: 10px;
                        }
                    }

                    input {

                        height: 2.5rem;
                        border-radius: 4px;
                        border: 1px solid lightgrey;
                        outline: none;
                        padding: 0 5px 0 5px;

                        &:hover {
                            border-color: $primary-color;
                            color: #111;
                        }
                    }




                    textarea {
                        height: 6rem;
                        border-radius: 4px;
                        border: 1px solid lightgrey;
                        outline: none;
                        padding: 10px;
                        font-size: 16px;

                        &:hover {
                            border-color: $primary-color;
                            color: #111;
                        }
                    }
                }

                .image_wrapper {
                    margin: 50px 0;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 10px;

                    label {
                        padding: 40px;
                        font-size: 30px;
                        border: 2px dashed lightgray;
                        cursor: pointer;
                    }

                    input {
                        display: none;
                    }

                    .image_sec {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 10px;
                        position: relative;

                        img {
                            width: 120px;
                            height: 118px;
                        }

                        .clearbtn {

                            position: absolute;
                            top: 5px;
                            right: 5px;
                            outline: none;

                            button {
                                background-color: #fff;
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                outline: none;
                                border: none;
                                font-size: 18px;
                            }

                        }

                    }

                }

                p {
                    font-size: 11px;
                    margin-top: 30px;

                }

            }

            .price_section {
                border-top: 1px solid $base-color2 ;
                padding: 20px 0;
            }

            .seller_section {
                border-top: 1px solid $base-color2 ;
                padding: 20px 0;

                .items {
                    display: flex;
                    flex-direction: column;
                    margin: 10px 0;
                    width: 100%;

                    input {

                        height: 2.5rem;
                        border-radius: 4px;
                        border: 1px solid lightgrey;
                        outline: none;
                        padding: 0 5px 0 5px;

                        &:hover {
                            border-color: $primary-color;
                            color: #111;
                        }
                    }
                }

                .location_wrap {
                    display: flex;
                    gap: 30px;

                    @include breakpoint(md) {
                        flex-direction: column;
                        gap: 5px;
                    }

                    @include mediumScreen {
                        gap: 40px;
                    }

                    @include LargeScreen {}


                    .col {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        padding: 5px 0;

                        @include breakpoint(md) {}

                        @include mediumScreen {}

                        @include LargeScreen {}

                        input {

                            height: 2.5rem;
                            border-radius: 4px;
                            border: 1px solid lightgrey;
                            outline: none;
                            padding: 0 5px;

                            @include breakpoint(md) {}

                            @include mediumScreen {}

                            @include LargeScreen {}
                        }
                    }
                }
            }

            .submit_section {
                position: relative;
                padding: 30px;
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    position: absolute;
                    right: 20px;
                    text-align: center;
                    padding: 10px 20px;
                    background-color: $primary-color;
                    color: #fff;
                    border: none;
                    border-radius: 4px;
                    font-size: 16px;
                    cursor: pointer;

                }
            }
        }

    }
}

/*--------End Registeration form-------  */