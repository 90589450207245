@import '../../../StyleHelpers/Variables.scss';



    .item_wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
     

        @include breakpoint(md) {}

        .wrapper {
            width: 300px;
            min-height: 500px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            border-radius: 10px;
            border: 3px solid $base-color4;
            margin: 10px;
            padding: 20px;

            @include breakpoint(md) {
                padding: 20px;
            }

            @include mediumScreen {
                padding: 20px;
            }


        
            .top {
                height:100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-bottom: 2px solid $base-color4;

                .row1 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 10px 0;

                    h3 {
                        font-size: 18px;
                        font-weight: 600;

                        @include breakpoint(md) {
                            font-size: 14px;
                        }

                        @include mediumScreen {}
                    }

                    h5 {
                        padding: 5px 10px;
                        background-color: $primary_color;
                        border-radius: 25px;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 300;

                        @include breakpoint(md) {
                            font-size: 10px;
                        }
                    }
                }

                .row2 {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 10px 0;
                    padding: 0 10px;

                    h1 {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        font-size: 38px;
                        color: $primary_color;

                        @include breakpoint(md) {
                            font-size: 28px;
                        }
                    }

                    span {
                        font-size: 38px;
                        font-weight: 900;
                        margin: 0 5px;

                        @include breakpoint(md) {
                            font-size: 28px;
                        }
                    }

                    h4 {
                        font-size: 12px;
                        color: $base-color2;

                        @include breakpoint(md) {
                            font-size: 10px;
                        }
                    }

                }

                .row3 {
                    padding: 5px 10px;

                    p {
                        font-size: 14px;
                        padding: 5px 10px;
                        border-radius: 25px;
                        background-color: $base-color3;
                        width: fit-content;
                    }
                }

                .row4 {
                    margin: 20px 0;

                    button {
                        width: 100%;
                        padding: 10px 0;
                        background: $gradient_color;
                        color: #fff;
                        border: none;
                        border-radius: 5px;
                        font-size: 16px;
                        font-weight: 200;
                        cursor: pointer;
                    }
                }
            }

            .bottom {
                min-height: 100px;
                display: flex;
                flex-direction: column;


                .title {
                    margin: 10px 0;

                    h2 {
                        font-size: 16px;

                    }
                }

                .row {
                    display: flex;
                    flex-direction: column;

                    .item {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        border-top: 1px solid $base-color4;
                        padding: 10px;

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $primary_color;
                            border: 2px solid $primary_color;
                            border-radius: 50%;
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }

            }



        }
    }


/*----- control btn -------*/
.control_btn {

    .next,
    .prev {
        position: absolute;
        top: 45%;
        width: 35px;
        height: 50px;
        line-height: 60px;
        background: #ffffff36;
        border-radius: 5px;
        border: none;
        transform: 0.5s;
        z-index: 5;


        i {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 50px;
            transition: 0.5s;
            color: #111;
            cursor: pointer;

        }

        &:hover {
            cursor: pointer;

            i {
                color: #111;
            }
        }
    }

    .prev {
        left: -20px;
    }

    .next {
        right: -20px;
    }
}

/*----- control btn -------*/


  

  .christmas_wrapper {
    background: linear-gradient(to bottom, #FFFAF0, #FFEBCD); /* Festive gradient */
    border: 2px solid #FF0000;
    border-radius: 16px;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-height: 450px;
}

.christmas_wrapper .top {
    position: relative;
}

.christmas_wrapper .strike_line {
    font-size:20px;
}

.christmas_wrapper .row2 h1 {
    display: flex;
    align-items: center;
    gap: 2px;
}

.christmas_wrapper .row3 p {
    font-size: 1rem;
    text-align: center;
    margin: 5px 0;
    color: #FF0000;
    font-weight: bold;
    color: white;
}

.christmas_wrapper .tree {
    position: absolute;
    bottom: -50px;  
    right: -30px;
}

.christmas_wrapper .row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.christmas_wrapper .item {
    background: #ffffff;
    padding: 10px;
    // border: 1px solid #000000;
    border-radius: 3px;
    display: flex;
    align-items: center;
    gap: 8px;
}

