@import "../../StyleHelpers/Variables.scss";


.Container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    //color: #fff;
    top: 3rem;
    width: 100%;
    min-height: 3rem;
    max-height: 16rem;
    border: 1px solid $base_color3;
    border-radius: 5px;
    overflow: hidden;
    z-index: 999;


    @include breakpoint(md) {}

    .Search_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        .recentSearch {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .eachItems {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 3rem;
                border-bottom: 1px solid $base-color4;
                cursor: pointer;

                &:hover {
                    background-color: $base_color3;
                }

                .left {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    img {
                        height: 2.5rem;
                        width: 50px;
                        border-radius: 100%;
                    }

                    h4 {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        font-size: 14px;
                        font-weight: 300;
                        margin: 0 5px;
                    }
                }

                .right {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    span {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        font-size: 18px;
                        margin: 0 5px;
                        font-weight: 700;
                    }
                }


            }
        }

        .ShowButton {
            width: 100%;
            display: flex;
            align-items: center;

            button {
                width: 100%;
                background-color: $primary_color;
                color: #fff;
                padding: 10px 0;
                border-radius: 5px;
                border: none;
                outline: none;
                cursor: pointer;

                &:hover {
                    background-color: #fff;
                    border: 1px solid $primary_color;
                    color: $primary_color;
                }
            }
        }

    }
}