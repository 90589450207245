@import '../../../StyleHelpers/Variables.scss';



.items {
    margin: 20px 40px;


    @include breakpoint(md) {
        margin: 10px;
    }

    @include mediumScreen {
        margin: 10px;
    }
}

/*----- control btn -------*/
.control_btn {

    .next,
    .prev {
        position: absolute;
        top: 45%;
        width: 35px;
        height: 50px;
        line-height: 60px;
        background: #ffffff36;
        border-radius: 5px;
        border: none;
        transform: 0.5s;
        z-index: 5;


        i {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 50px;
            transition: 0.5s;
            color: #111;
            cursor: pointer;

        }

        &:hover {
            cursor: pointer;

            i {
                color: #111;
            }
        }
    }

    .prev {
        left: -20px;
    }

    .next {
        right: -20px;
    }
}

/*----- control btn -------*/