.OtpSection {
  width: 480px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
}
@media (max-width: 550px) {
  .OtpSection {
    height: 420px;
    padding: 20px;
    margin: 0;
    place-content: center;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .OtpSection {
    height: 340px;
    padding: 20px 40px;
  }
}
.OtpSection .navigation {
  text-decoration: none;
  color: #111;
}
.OtpSection h1 {
  font-size: 24px;
  padding: 10px 0;
}
.OtpSection p {
  font-size: 14px;
}
.OtpSection form {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 550px) {
  .OtpSection form {
    width: 100%;
    padding: 0;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .OtpSection form {
    width: 100%;
  }
}
.OtpSection form label {
  font-size: 18px;
  font-weight: 500;
  margin: 10px;
}
@media (max-width: 550px) {
  .OtpSection form label {
    margin: 10px 0;
  }
}
.OtpSection form .input_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.OtpSection form .input_div input {
  width: 90%;
  border: none;
  outline: none;
  background-color: #f8f8f8;
  padding: 15px 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 2px solid lightgrey;
}
.OtpSection form .input_div .otpbtn_div {
  width: 90%;
  padding: 10px 0 10px 30px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.OtpSection form .input_div .otpbtn_div .otpbtn {
  text-align: center;
  padding: 12px 25px;
  background-color: rgb(42, 197, 42);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}/*# sourceMappingURL=index.module.css.map */