.products {
  width: 100%;
  max-width: 250px;
  min-width: 140px;
  transition: box-shadow 0.35s ease;
  background-color: #fff;
  margin-bottom: 0 !important;
  border-radius: 5px;
  cursor: pointer;
}
.products:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
.products:hover .productmedia .productAction, .products:focus .productmedia .productAction {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.products .productmedia {
  position: relative;
  display: block;
  background-color: #fff;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 0.2rem;
  overflow: hidden;
}
.products .productmedia .productlabel {
  position: absolute;
  z-index: 1;
  top: 0.5rem;
  left: 0.5rem;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 1rem;
  letter-spacing: -0.01em;
  padding: 0.4rem 0.6rem;
  min-width: 40px;
  text-align: center;
  color: #fff;
  background-color: #046BD2;
}
@media (max-width: 550px) {
  .products .productmedia .productlabel {
    top: 0.5rem;
    left: 0.5rem;
    font-size: 0.6rem;
    padding: 0.2rem 0.4rem;
  }
}
.products .productmedia .productImage {
  display: block;
  width: 100%;
  min-height: 180px;
  max-height: 230px;
  border-style: none;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
  background-color: #fff;
}
@media (max-width: 550px) {
  .products .productmedia .productImage {
    height: 180px;
  }
}
.products .productmedia .productAction {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 10;
  transition: all 0.35s ease;
  opacity: 0;
  visibility: hidden;
  padding: 0.6rem 0.4rem;
  border-top: 0.1rem solid #ebebeb;
  border-bottom: 0.1rem solid #ebebeb;
  transform: translateY(100%);
}
.products .productmedia .productAction span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: center;
  color: #3399ff;
  font-weight: 400;
  font-size: 14px;
}
@media (max-width: 550px) {
  .products .productmedia .productAction span {
    flex-direction: column;
    font-size: 12px;
  }
}
.products .productmedia .productAction span i {
  display: flex;
  align-items: center;
}
@media (max-width: 550px) {
  .products .productmedia .productAction span i {
    font-size: 14px;
  }
}
.products .productbody {
  position: relative;
  padding: 0.8rem;
  transition: all 0.35s ease;
}
.products .productbody .product_cat {
  color: #666;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.products .productbody .product_title {
  margin-bottom: 0.2rem;
}
.products .productbody .product_title h3 {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.products .productbody .product_price {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25;
  color: #3399ff;
  margin-bottom: 0.2rem;
}/*# sourceMappingURL=index.module.css.map */