.breadcrumb {
  border-bottom: 0.1rem solid rgba(235, 235, 235, 0.55);
}
.breadcrumb .container {
  padding: 1rem 3rem;
}
@media (max-width: 550px) {
  .breadcrumb .container {
    padding: 1.5rem 1rem;
  }
}
.breadcrumb .container .breadcrumb_wrap {
  background-color: transparent;
  border-radius: 0;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.breadcrumb .container .breadcrumb_wrap .item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: capitalize;
  margin-right: 5px;
}
.breadcrumb .container .breadcrumb_wrap .item span {
  display: flex;
  align-items: center;
}
.breadcrumb .container .breadcrumb_wrap .item span .navlink {
  text-decoration: none;
  color: #046BD2;
}
@media (max-width: 550px) {
  .breadcrumb .container .breadcrumb_wrap .item span .navlink {
    font-size: 14px;
  }
}
.breadcrumb .container .breadcrumb_wrap .item .navlink {
  text-decoration: none;
  color: #046BD2;
}
.breadcrumb .container .breadcrumb_wrap .item i {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
}/*# sourceMappingURL=index.module.css.map */