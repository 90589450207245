@import '../../../StyleHelpers/Variables.scss';

.single {
  display: flex;
  width: 100%;

  .singleContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        border-radius: 5px;
        position: relative;

        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px 20px;
          font-size: 12px;
          color: #fff;
          background-color: $primary_color;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
        }


        .item {
          display: flex;
          gap: 30px;
          padding: 20px 0;

          .itemImg {
            flex: 2;
            display: flex;
            justify-content: center;
            align-items: center;

            .squareImg{
              width: 200px;
              height: 200px;
              border-radius: 0%;
            }

            img {
              width: 100px;
              height: 100px;
              border-radius: 100%;
              // object-fit: cover;
            }
          }

          .AdsImg{
            flex: 6;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
              width: 100%;
              height: 150px;
            }
          }

          .details {
            flex: 6;

            .itemTitle {
              margin-bottom: 10px;
              color: #555;
            }

            .detailItem {
              margin-bottom: 10px;
              font-size: 14px;

              .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 5px;
              }

              .itemValue {
                font-weight: 300;
              }

              .featureDetails {
                display: flex;
                //align-items: center;
                gap: 5px;
                margin: 10px;
              }
            }
          }
        }
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }

    .title {
      font-size: 16px;
      color: lightgray;
      margin-bottom: 20px;
    }
  }
}