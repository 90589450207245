@import '../../../StyleHelpers/Variables.scss';

.new {
    width: 100%;
    display: flex;

    .newContainer {
        flex: 6;

        .bottom,
        .center,
        .bottomTable {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        }

        .bottomTable {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .top,
        .bottom,
        .center,
        .bottomTable {
            padding: 10px;
            margin: 20px;
            border-radius: 5px;
            display: flex;



            h1 {
                color: $base_color4;
                font-size: 20px;
            }

            .left {
                flex: 1;
                text-align: center;
                border-right: 1px solid $base_color4 ;

                img {
                    width: 120px;
                    height: 120px;
                    margin-top: 20px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .right {
                flex: 2;
                padding: 10px 20px;

                form {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;
                    justify-content: space-between;
                    margin: 10px 0px;

                    .formImg {
                        width: 100%;
                        padding: 10px;
                    }

                    .formInput {
                        width: 40%;

                        label {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            span {
                                color: red;
                            }

                            .icon {
                                cursor: pointer;
                            }
                        }

                        input {
                            width: 100%;
                            padding: 10px 0;
                            border: none;
                            outline: none;
                            background: none;
                            border-bottom: 1px solid gray;
                        }

                        select {
                            width: 100%;
                            padding: 10px 0;
                            border: none;
                            outline: none;
                            border-bottom: 1px solid gray;
                        }
                    }


                    //Edit plan form styling
                    .featuresForm {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding: 20px 0;
                        gap: 40px;

                        .formInput {
                            width: 40%;
                        }

                        .featurebtn {
                            span {
                                padding: 10px 20px;
                                background-color: $primary_color;
                                color: #fff;
                                border: none;
                                border-radius: 5px;
                                cursor: pointer;
                            }
                        }
                    }

                    //End edit plan form styling

                    .formproperty {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        textarea {
                            height: 6rem;
                            width: 100%;
                            border: none;
                            outline: none;
                            border-bottom: 1px solid gray;
                        }

                        .formWrapper {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            align-items: center;
                            gap: 10px;

                            .formInput {
                                width: 30%;
                            }
                        }

                        .formWrap {
                            display: flex;
                            flex-direction: column;

                            .formInput {
                                width: 100%;
                                margin: 10px 0;
                            }
                        }

                        .formWrapper,
                        .formWrap {
                            .formInput {
                                label {
                                    span {
                                        color: red;
                                        border: none;
                                    }

                                    .icon {
                                        cursor: pointer;
                                    }
                                }

                                input {
                                    width: 100%;
                                    padding: 10px 0;
                                    border: none;
                                    outline: none;
                                    border-bottom: 1px solid gray;
                                }

                                textarea {
                                    height: 10rem;
                                    width: 100%;
                                    padding: 10px 0;
                                    border: none;
                                    outline: none;
                                    border-bottom: 1px solid gray;
                                }
                            }

                            //NoticeForm form button wrap
                            .formButtonWrap {
                                width: 100%;
                                display: flex;
                                justify-content: flex-end;
                                align-items: flex-end;

                                .formButton {
                                    padding: 10px 20px;
                                    border: none;
                                    background-color: $primary_color;
                                    border-radius: 5px;
                                    color: white;
                                    cursor: pointer;
                                }
                            }

                            //End noticeForm form button wrap

                            .formSelect {
                                width: 15%;

                                label {
                                    margin: 0 5px;

                                    span {
                                        color: red;
                                        border: none;
                                    }
                                }

                                select {
                                    width: 100%;
                                    padding: 10px 0;
                                    border: none;
                                    outline: none;
                                    border-bottom: 1px solid gray;
                                }
                            }

                            .newform {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                padding: 20px 0;
                                gap: 40px;

                                .formInput {
                                    width: 40%;
                                }
                            }
                        }

                        .toggleBtn {
                            width: 180px;
                            padding: 10px 20px;
                            border: 1px solid $primary_color;
                            background-color: #fff;
                            border-radius: 5px;
                            color: $primary_color;
                            font-weight: bold;
                            text-align: center;
                            cursor: pointer;
                        }

                        .propertyBtn {
                            padding: 10px 20px;
                            border: none;
                            background-color: $primary_color;
                            border-radius: 5px;
                            color: white;
                            cursor: pointer;
                        }
                    }

                    .formBtn {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;

                        button {
                            width: 150px;
                            padding: 10px;
                            border: none;
                            border-radius: 5px;
                            background-color: $primary_color;
                            color: white;
                            font-weight: bold;
                            margin: 20px 0;
                            cursor: pointer;
                        }
                    }
                }
            }

            //---bottom container-----

            .title {
                font-size: 16px;
                color: $base_color4;
                margin: 10px 0;
            }

            .details {
                flex: 1;
                padding: 10px 20px;
                border-radius: 5px;
                position: relative;
                border: 1px solid $base_color3;
                display: flex;
                justify-content: space-between;

                .left_wrap {
                    display: flex;
                    flex-direction: column;
                }

                .left {
                    display: flex;
                    align-items: center;
                }

                .left,
                .left_wrap {
                    flex: 6;

                    .detailItem {
                        margin-bottom: 10px;
                        font-size: 14px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .itemKey {
                            font-weight: bold;
                            color: gray;
                            margin-right: 5px;
                        }

                        .itemValue {
                            font-weight: 300;
                        }
                    }

                    .option_wrap {
                        margin-bottom: 10px;
                        font-size: 14px;
                        display: flex;
                        flex-direction: column;

                        .option_title {
                            font-size: 16px;
                            margin: 10px 0;
                        }

                        .Items {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 10px;

                            .Item {
                                display: flex;
                                gap: 2px;
                            }
                        }
                    }
                }

                .right {
                    flex: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    button {
                        background-color: #fff;
                        color: red;
                        border-radius: 5px;
                        border: none;
                        cursor: pointer;
                    }

                    .rembtn {
                        padding: 10px;
                        background-color: red;
                        color: #fff;
                        border-radius: 5px;
                        border: none;
                        margin: 10px 0;
                        cursor: pointer;
                    }
                }
            }

        }
    }
}