.home_container {
  width: 100%;
  margin-top: 5rem;
  position: relative;
}
@media (max-width: 550px) {
  .home_container {
    margin-top: 7rem;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .home_container {
    margin-top: 110px;
  }
}
.home_container .Main_container {
  display: flex;
  justify-content: center;
  gap: 0.2rem;
  min-height: 510px;
  overflow: hidden;
  padding: 0 60px;
  /* LeftCategory.module.scss */
}
@media (max-width: 550px) {
  .home_container .Main_container {
    min-height: 510px;
    padding: 0;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .home_container .Main_container {
    min-height: 510px;
    padding: 0;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .home_container .Main_container {
    padding: 0;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .home_container .Main_container {
    padding: 0 40px;
  }
}
.home_container .Main_container .left_cat {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transform: translateY(-30px); /* Adjusted for top-to-bottom animation */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out, max-height 0.5s ease-out, visibility 0s 0.5s;
  height: auto;
}
.home_container .Main_container .left_catenter {
  opacity: 1;
  visibility: visible;
  max-height: 100%; /* Set to a value that accommodates the content */
  transform: translateY(0);
  transition-delay: 0s, 0s, 0s, 0s;
}
.home_container .Main_container .Left {
  background-color: #f8f8f8;
  width: 21%;
  max-width: 340px;
  height: 1750px;
  overflow-y: auto;
}
@media (max-width: 550px) {
  .home_container .Main_container .Left {
    display: none;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .home_container .Main_container .Left {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .home_container .Main_container .Left {
    display: none;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .home_container .Main_container .Left {
    width: 25%;
  }
}
.home_container .Main_container .Right {
  width: 77%;
  max-width: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 550px) {
  .home_container .Main_container .Right {
    width: 100%;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .home_container .Main_container .Right {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .home_container .Main_container .Right {
    width: 100%;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .home_container .Main_container .Right {
    width: 70%;
  }
}
.home_container .Main_container .Right .cardWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f8f8f8;
  margin-top: 0.2rem;
  padding: 2rem 0;
}
.home_container .Main_container .Right .cardWrapper .loadbtn {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 3rem 0 1rem 0;
}
.home_container .Main_container .Right .cardWrapper .loadbtn .inactiveBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #666;
  background-color: transparent;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.home_container .Main_container .Right .cardWrapper .loadbtn .inactiveBtn:hover {
  color: #666;
}
.home_container .Main_container .Right .cardWrapper .loadbtn .activeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #666;
  background-color: transparent;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.home_container .Main_container .Right .cardWrapper .loadbtn .activeBtn:hover {
  color: #046BD2;
}
.home_container .Main_container .Right .cardWrapper .loadbtn .activeBtn .icon {
  font-size: 20px;
  font-weight: 900;
}
.home_container .Reffermodal_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}/*# sourceMappingURL=index.module.css.map */