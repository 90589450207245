.page_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.page_wrapper .main {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 550px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .page_wrapper .main {
    margin-top: 80px;
  }
}
.page_wrapper .main .container {
  min-height: 510px;
  display: flex;
  justify-content: center;
  padding: 0 3rem;
}
@media (max-width: 550px) {
  .page_wrapper .main .container {
    flex-direction: column;
    padding: 0 1rem;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main .container {
    flex-direction: column;
    padding: 0 2rem;
  }
}
.page_wrapper .main .container .left {
  flex: 2;
}
.page_wrapper .main .container .right {
  flex: 6;
}/*# sourceMappingURL=index.module.css.map */