@import '../../../StyleHelpers/Variables.scss';

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    
    position: relative;

    .navigation {
        text-decoration: none;
        color: #111;
    }


    .header_wrapper {
        width: 100%;
        height: 80px;
        background-color: #fff;
        position: fixed;
        top: 0;
        z-index: 9;
        display: flex;
        align-items: center;

        .backarrow {
            padding: 0 20px;
            font-size: 24px;
            margin: 0 20px;
        }
    }  
    
    .under_heading {
        margin-top: 5% ;
        background-color: #f3f3f3;
        padding: 2rem 0rem;
        font-family:"Roboto", sans-serif;
        font-weight: 300;
         
        div{
            color : #585858;
            padding-left: 5rem;

            h5 {
                margin-top:.5rem;
                color: #3d3d3d;
            }
        }
    }
}