.charts {
  flex: 6;
  padding: 10px;
  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.57);
  border-radius: 5px;
}
.charts .title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}/*# sourceMappingURL=Style.module.css.map */