@import '../../../StyleHelpers/Variables.scss';

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .main {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            margin-top: 120px;
        }

        @include mediumScreen {
            margin-top: 120px;
        }

        @include LargeScreen {
            margin-top: 80px;
        }


        .productwrapper {
            padding: 1.5rem 0;
            min-height: 35rem;
            background-color: #f8f8f8;

            .container {
                margin: 0 4rem;
                display: block;
                padding: 0 20px;

                @include breakpoint(md) {
                    margin: 0 0;
                    padding: 0 20px;
                }

                @include mediumScreen {
                    margin: 0 1rem;
                }

                .heading {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 2rem;
                    padding: .5rem 0;
                    border-bottom: 2px solid $base_color;
                }

                .cardWrapper {
                    overflow: hidden;
            display: flex;
            flex-wrap: wrap ;
            // display: grid;
            // // grid-auto-columns: auto auto auto;
            // grid-template-columns:auto auto auto auto;
            // place-items: center;
            // place-content: center;
            gap: 10px;


            @include breakpoint(md) {
                grid-template-columns: 1fr 1fr;
                gap: 5px;
            
            }

            @include mediumScreen {
                grid-template-columns: 1fr 1fr 1fr;
                gap: 5px;
            }

            @include extraMediumScreen {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            @include LargeScreen {
                grid-template-columns: 1fr 1fr 1fr;
            }
                }
            }
        }

    }
}