.box {
  padding: 0px 0;
}
.box .img_Container {
  width: auto;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.box .img_Container img {
  -o-object-fit: cover;
     object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=index.module.css.map */