@import '../../../StyleHelpers/Variables.scss';

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;

    .navigation {
        text-decoration: none;
        color: #111;
    }


    .header_wrapper {
        width: 100%;
        height: 80px;
        background-color: #fff;
        position: fixed;
        top: 0;
        z-index: 9;
        display: flex;
        align-items: center;

        .backarrow {
            padding: 0 20px;
            font-size: 24px;
            margin: 0 20px;
        }
    }

    .container_wrapper {
        height: 100%;
        min-height: 540px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $base-color3;
        margin-top: 80px;
        padding: 20px 80px;
        gap: 5px;
        overflow: hidden;

        @include breakpoint(md) {
            display: none;
        }

        @include mediumScreen {
            min-height: 480px;
            padding: 20px;
        }

        @include LargeScreen {
            min-height: 510px;
        }

        .container {
            display: flex;
            background-color: #fff;
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
            gap: 5px;

            .left_container {
                display: flex;
                flex-direction: column;
                min-width: 340px;
                min-height: 420px;
                padding: 10px;
                border-radius: 5px;
                background-color: #fff;
                box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

                @include mediumScreen {
                    min-width: 250px;
                }

                .box_title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 2rem;
                    padding: 5px 0;
                    margin-bottom: 5px;
                    background-color: $primary-color;
                    color: #fff;
                    border-radius: 5px;

                }

                .normal {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    width: 100%;
                    height: 2rem;
                    padding: 5px 0;
                    border-bottom: 2px solid $base-color3;

                    &:hover {
                        background-color: $primary-color;
                        color: #fff;
                        border-radius: 5px;
                    }

                    label {
                        display: flex;
                        align-items: flex-start;
                        width: 80%;
                        padding: 0 10px;
                    }

                    span {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        width: 20%;
                        padding: 10px;
                        font-size: 22px;

                    }
                }
                .hightlight{
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    width: 100%;
                    height: 2rem;
                    padding: 5px 0;
                    border-bottom: 2px solid $base-color3;
                    background-color:$primary-color;
                    color:white;
                    border-radius:5px ;

                    &:hover {
                        background-color: $primary-color;
                        color: #fff;
                        border-radius: 5px;
                    }

                    label {
                        display: flex;
                        align-items: flex-start;
                        width: 80%;
                        padding: 0 10px;
                    }

                    span {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        width: 20%;
                        padding: 10px;
                        font-size: 22px;

                    }

                }
               
            }

            .right_container {
                display: flex;
                flex-direction: column;
                min-width: 400px;
                min-height: 420px;
                border-radius: 5px;
                padding: 10px;
                background-color: #fff;
               
                @include mediumScreen {
                    min-width: 250px;
                }

                .box {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    width: 100%;
                    height: 2rem;
                    padding: 5px 0;
                    border-bottom: 2px solid $base-color3;

                    &:hover {
                        background-color: $primary-color;
                        color: #fff;
                        border-radius: 5px;
                    }

                    label {
                        display: flex;
                        align-items: flex-start;
                        width: 100%;
                        padding: 0 10px;
                    }

                    span {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        width: 20%;
                        padding: 10px;
                        font-size: 22px;

                    }
                }
            }
        }
    }

    .M_container {

        display: none;

        @include breakpoint(md) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 80px;
            padding: 10px 20px;
            min-height: 510px;
            background-color: $base-color3;
            overflow: hidden;
        }

        @include mediumScreen {
            display: none;
        }

        .Container {
            display: flex;
            flex-direction: column;
            width: 100%;
            min-height: 420px;
            padding: 20px;
            background-color: #fff;

            .left_container {

                @include breakpoint(md) {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                .box_title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 2rem;
                    padding: 5px 0;
                    margin-bottom: 5px;
                    background-color: $primary-color;
                    color: #fff;
                    border-radius: 5px;

                    h3 {
                        font-size: 16px;
                    }

                }

                .box {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    width: 100%;
                    height: 2rem;
                    padding: 5px 0;
                    border-bottom: 2px solid $base-color3;

                    &:hover {
                        background-color: $primary-color;
                        color: #fff;
                    }

                    label {
                        display: flex;
                        align-items: flex-start;
                        width: 100%;
                        padding: 0 10px;
                    }

                    span {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        width: 20%;
                        padding: 10px;
                        font-size: 22px;

                    }
                }
            }
        }
    }
}