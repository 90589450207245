@import '../../../StyleHelpers/Variables.scss';

.datatable {
    height: 500px;
    padding: 20px;

    .datatableTitle {
        width: 100%;
        font-size: 24px; 
        color: gray;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .link {
            text-decoration: none;
            color: green;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid green;
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .datagrid{
        border: 1px solid $base_color4;
        border-radius: 5px;
        min-height: 460px;
    }

    .cellWithImg {
        display: flex;
        align-items: center;

        .cellImg {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }
    }

    .cellWithStatus {
        padding: 5px;
        border-radius: 5px;

        &.active {
            background-color: rgba(0, 128, 0, 0.05);
            color: green;
        }

        &.pending {
            background-color: rgba(255, 217, 0, 0.05);
            color: goldenrod;
        }

        &.passive {
            background-color: rgba(255, 0, 0, 0.05);
            color: crimson;
        }
    }

   
}