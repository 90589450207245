@import '../../StyleHelpers/Variables.scss';

.form_container {
    width: 45rem;
    min-height: 25rem;
    display: flex;
    // padding: 1rem;
    //flex-direction: column;
    //justify-content: center;
    border-radius: 5px;
    box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.31);

    @include breakpoint(md) {
        width: 20rem;
        padding: 1rem 2rem;
        min-height: 20rem;
        transition: 0.5s ease-in-out;
        box-shadow: none;
    }

    @include mediumScreen {
        width: 30rem;
    }

    @include LargeScreen {
        width: 45rem;
    }

    .left {
        display: flex;
        flex: 2;
        flex-direction: column;
        justify-content: center;
        padding: 1rem 2rem;

        h1 {
            color: $primary_color;
        }

        p {
            font-size: .8rem;
            padding: 1rem 0;
        }

        form {

            div {
                display: flex;
                flex-direction: column;

                p {
                    color: red;
                    text-align: start;
                    font-size: 12px;

                }

                label {
                    font-size: .8rem;
                }

                input {
                    border-width: .3px;
                    border-color: rgb(149 149 149 / 52%);
                    outline: none;
                    padding: 15px 10px;
                    background-color: $base_color;
                    border-radius: 5px;
                }
            }

            button {
                cursor: pointer;
                margin-top: 5px;
                background-color: $primary_color;
                width: 100%;
                color: white;
                border: none;
                border-radius: 5px;
                padding: 15px 0px;
                font-size: 15px;

                &:hover {
                    background: $gradient_color;
                }
            }

            p {
                font-size: .8rem;
                text-align: center;
                padding-top: .5rem;
                cursor: pointer;
            }
        }
    }

    .right {
        width: 100%;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: $primary_color;

        @include breakpoint(md) {
            display: none;
        }

        img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
}