@import '../../StyleHelpers/Variables.scss';

.productwrapper {
    width: 100%;
    background-color: #f8f8f8;

    @include breakpoint(md) {
        display: none;
    }

    @include mediumScreen {
        display: none;
    }

    .container {
        margin: 4rem 4rem;
        display: block;
        padding: 0 20px;

        @include breakpoint(md) {
            margin: 0 0;
            padding: 0 10px;
        }

        @include mediumScreen {
            margin: 0 1rem;
        }

        .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2rem;
            padding: 1rem 0;
            border-bottom: 2px solid #ebebeb;

            .left {
                h2 {
                    color: $base-color5;
                    font-weight: 600;

                    @include breakpoint(md) {
                        font-size: 16px;
                    }
                }
            }

            .right {
                span {
                    cursor: pointer;
                    color: $primary_color;

                    @include breakpoint(md) {
                        font-size: 12px;
                    }
                }
            }
        }

        .cardWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            place-items: center;
            place-content: center;
            gap: 20px;


            @include breakpoint(md) {
                grid-template-columns: 1fr 1fr;
                gap: 10px 20px;
            }

            @include mediumScreen {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @include extraMediumScreen {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            @include LargeScreen {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
}