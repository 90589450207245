@import '../../../StyleHelpers/Variables.scss';



//----- otp styling ------
.OtpSection {
    width: 480px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);


    @include breakpoint(md) {
        height: 420px;
        padding: 20px;
        margin: 0;
        place-content: center;
    }

    @include mediumScreen {
        height: 340px;
        padding: 20px 40px;
    }


    .navigation {
        text-decoration: none;
        color: #111;
    }

    h1 {
        font-size: 24px;
        padding: 10px 0;
    }

    p {
        font-size: 14px;
    }

    form {
        width: 100%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include breakpoint(md) {
            width: 100%;
            padding: 0;
        }

        @include mediumScreen {
            width: 100%;
        }

        @include LargeScreen {}

        label {
            font-size: 18px;
            font-weight: 500;
            margin: 10px;

            @include breakpoint(md) {
                margin: 10px 0;
            }

        }

        .input_div {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            input {
                width: 90%;
                border: none;
                outline: none;
                background-color: $base_color3;
                padding: 15px 10px;
                margin: 10px 0;
                border-radius: 5px;
                border: 2px solid $base_color4;
            }

            .otpbtn_div {
                width: 90%;
                padding: 10px 0 10px 30px;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                .otpbtn {
                    text-align: center;
                    padding: 12px 25px;
                    background-color: rgb(42, 197, 42);
                    color: #fff;
                    border: none;
                    border-radius: 4px;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }
    }
}

//----- End otp styling ------