.modal_overlay{
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}
.modal_contant{
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #bbb;
}