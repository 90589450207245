@import '../../../StyleHelpers/Variables.scss';

/* user profile styling */

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .main {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            margin-top: 120px;
        }

        @include mediumScreen {
            margin-top: 120px;
        }

        @include LargeScreen {
            margin-top: 80px;
        }

        // -- User Profile Styling --

        .Main_Container {
            min-height: 100vh;
            display: flex;
            justify-content: center;
            background-color: #fff;
            padding: 0 3rem;
            overflow: hidden;
            gap: 5px;

            @include breakpoint(md) {
                flex-direction: column;
                padding: 0.5rem;
            }

            @include mediumScreen {
                flex-direction: column;
                padding: 0.5rem;
            }

            .Left_Section {
                flex: 2;
                @include breakpoint(md) {
                    display: none;
                }

                @include mediumScreen {
                    display: none;
                }
            }

            .Right_Section{
                flex: 6;

                @include breakpoint(md) {
                    flex: 1;
                }

                @include mediumScreen {
                    flex: 1;
                }
            }
        }
    }
}