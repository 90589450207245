@import "../../StyleHelpers/Variables.scss";


.option_wrapper {
    position: absolute;
    top: 2.6rem;
    right: -5px;
    margin-top: 25px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.67);
    border-radius: 5px;
    z-index: 9999;



    &:after {
        content: " ";
        position: absolute;
        right: 5%;
        top: -7px;
        border-top: none;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: 15px solid #fff;

    }

    .navigation {
        text-decoration: none;
        color: #111;
        width: 100%;
    }

    .box {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-color: white;
        min-width: 12rem;
        border-radius: 8px;
        padding: 5px;
        position: relative;


        .boxWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            cursor: pointer;

            .eachopt {
                border-bottom: 1px solid $base_color4;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .logout {
                width: 100%;
            }

            .eachopt,
            .logout {
                padding: 5px 0;

                &:hover {
                    background-color: $base_color3;
                }

                h4 {
                    margin: 4px 10px;
                    font-size: 12px;
                    font-weight: 400;
                    cursor: pointer;
                }

                .count {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 10px;
                    padding: 2px;
                    width: 1rem;
                    height: 1rem;
                    font-size: 0.6rem;
                    color: #fff;
                    background-color: $primary_color;
                    border-radius: 50%;
                }
            }

            .User_wrapper {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                padding: 10px 0;
                border-bottom: 1px solid $base_color4;


                .profile {
                    aspect-ratio: 1;
                    width: 40px;
                    height: 40px;
                    margin: 0 10px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

            }
        }
    }
}