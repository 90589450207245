@import '../../../StyleHelpers/Variables.scss';


.content {
    width: 100%;
    height: 100%;

    .contentWrapper {
        display: flex;
        flex-direction: column;
        padding: 40px 10px;

        @include breakpoint(md) {
            flex-direction: column;
        }

        @include mediumScreen {
            flex-direction: column;
        }

        .top {
            margin: 20px 0;
        }

        .top,
        .bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title {
                display: flex;
                align-items: start;
                justify-content: flex-start;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                width: 100%;

                .active {
                    width: 100%;
                    font-weight: 700;
                    color: $primary_color;
                }

                li {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    cursor: pointer;

                    &:hover {
                        width: 100%;
                        background-color: $base_color3;
                        color: #111;
                        border-radius: 3px;
                    }
                }
            }
        }
    }


}