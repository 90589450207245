/* user profile styling */
.page_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.page_wrapper .main {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 550px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .page_wrapper .main {
    margin-top: 80px;
  }
}
.page_wrapper .main .Main_Container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 0 3rem;
  overflow: hidden;
  gap: 5px;
}
@media (max-width: 550px) {
  .page_wrapper .main .Main_Container {
    flex-direction: column;
    padding: 0.5rem;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main .Main_Container {
    flex-direction: column;
    padding: 0.5rem;
  }
}
.page_wrapper .main .Main_Container .Left_Section {
  flex: 2;
}
@media (max-width: 550px) {
  .page_wrapper .main .Main_Container .Left_Section {
    display: none;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main .Main_Container .Left_Section {
    display: none;
  }
}
.page_wrapper .main .Main_Container .Right_Section {
  flex: 6;
}
@media (max-width: 550px) {
  .page_wrapper .main .Main_Container .Right_Section {
    flex: 1;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main .Main_Container .Right_Section {
    flex: 1;
  }
}/*# sourceMappingURL=Style.module.css.map */