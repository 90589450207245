.Container {
  display: block;
  min-height: 100%;
}
.Container .icon {
  font-size: 14px;
  font-weight: 900;
}
.Container .top {
  margin-bottom: 0.5rem;
}
.Container .top .filterDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: #fff;
}
.Container .top .filterDiv .headingDiv {
  display: flex;
  align-items: center;
  gap: 5px;
}
.Container .top .filterDiv .clearDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #046BD2;
  cursor: pointer;
}
.Container .top .filterDiv .clearDiv span {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.Container .top .filterDiv .clearDiv .icon {
  font-size: 14px;
  font-weight: 900;
}
.Container .top .selectedFilterDiv {
  margin-bottom: 0.5rem;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 1rem;
}
.Container .top .selectedFilterDiv .wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f8f8f8;
  min-width: 70px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 25px;
  gap: 10px;
  padding: 5px;
  font-size: 14px;
}
.Container .top .selectedFilterDiv .wrap span {
  cursor: pointer;
}
.Container .bottom .accordion {
  display: flex;
  flex-direction: column;
}
.Container .bottom .accordion .titleDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0.5rem;
}
.Container .bottom .accordion .titleDiv h3 {
  font-size: 16px;
  font-weight: 500;
}
.Container .bottom .accordion .titleDiv span {
  display: flex;
  align-items: center;
}
.Container .bottom .accordion .content {
  background-color: white;
  overflow-y: hidden;
  max-height: 0;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  display: none;
}
.Container .bottom .accordion .show {
  background-color: white;
  display: flex;
  align-items: center;
  margin: 2px 0;
  padding: 0.8rem;
}
@media (max-width: 550px) {
  .Container .bottom .accordion .show {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .Container .bottom .accordion .show {
    display: flex;
    align-items: center;
  }
}
.Container .bottom .accordion .show .items {
  width: 100%;
  min-height: 3rem;
  max-height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  color: #111;
  background-color: #ffffff;
  overflow-y: auto;
  cursor: pointer;
}
.Container .bottom .accordion .show .items .items_wrapper {
  width: 100%;
}
.Container .bottom .accordion .show .items .items_wrapper p {
  margin: 0.4rem 0.6rem;
}
.Container .bottom .accordion .show .items .items_wrapper:hover {
  background-color: #ebebeb;
  border-radius: 5px;
  color: #111;
}
.Container .bottom .accordion .show .items .items_wrapper:active,
.Container .bottom .accordion .show .items .items_wrapper :focus {
  background-color: #ebebeb;
  border-radius: 5px;
  color: #111;
}
.Container .bottom .accordion .show .items .radioField_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 4px 0;
  cursor: pointer;
}
.Container .bottom .accordion .show .onSelectField {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 20px;
  cursor: pointer;
}
.Container .bottom .accordion .show .onSelectField .items_wrapper {
  width: 100%;
}
.Container .bottom .accordion .show .onSelectField .items_wrapper .item_Title {
  padding-bottom: 2px;
}
.Container .bottom .accordion .show .onSelectField .items_wrapper .item_Title h3 {
  font-size: 15px;
  font-weight: 500;
}
.Container .bottom .accordion .inputContent {
  background: #fff;
  padding: 0.5rem 1rem;
  margin: 2px 0;
}
.Container .bottom .accordion .inputContent .inputFields {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0.8rem;
}
.Container .bottom .accordion .inputContent .inputFields .field_wrapper {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.Container .bottom .accordion .inputContent .inputFields .field_wrapper input {
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 5px;
  font-size: 15px;
  text-align: center;
  border: 1px solid #ccc;
  --moz-appearance: none;
}
.Container .bottom .accordion .inputContent .inputFields .field_wrapper input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
          appearance: textfield;
}
.Container .bottom .accordion .inputContent .inputFields .field_wrapper input[type=number]::-webkit-inner-spin-button, .Container .bottom .accordion .inputContent .inputFields .field_wrapper input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Container .bottom .accordion .inputContent .inputFields .seperation {
  width: 80px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Container .bottom .accordion .inputContent .inputFields .field_searchBtn {
  width: 80px;
  height: 40px;
}
.Container .bottom .accordion .inputContent .inputFields .field_searchBtn button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #046BD2;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
}
.Container .bottom .accordion .inputContent .searchBtn {
  width: 100%;
  height: 40px;
}
.Container .bottom .accordion .inputContent .searchBtn button {
  width: 100%;
  height: 100%;
  background: #046BD2;
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
}
.Container .bottom .accordion .inputContent .radioFields {
  min-height: 5rem;
  max-height: 12rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.Container .bottom .accordion .inputContent .radioFields .radioField_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 0;
  width: 100%;
  cursor: pointer;
}
.Container .bottom .accordion .inputContent .slider_wrapper {
  padding: 1rem 0;
  border-top: 2px solid #f8f8f8;
}
.Container .bottom .accordion .inputContent .slider_wrapper p {
  margin-bottom: 0.8rem;
  font-size: 14px;
}
.Container .bottom .accordion .inputContent .slider_wrapper .slider_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}
.Container .bottom .accordion .inputContent .slider_wrapper .slider {
  width: 100%;
}
.Container .bottom .accordion .inputContent .slider_wrapper .slider .progress_wrap {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #ebebeb;
  position: relative;
}
.Container .bottom .accordion .inputContent .slider_wrapper .slider .progress_wrap .progress {
  height: 5px;
  position: absolute;
  left: 25%;
  right: 25%;
  border-radius: 5px;
}
.Container .bottom .accordion .inputContent .slider_wrapper .slider .rangeInput {
  position: relative;
}
.Container .bottom .accordion .inputContent .slider_wrapper .slider .rangeInput input {
  position: absolute;
  top: -5px;
  height: 5px;
  width: 100%;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
}
.Container .bottom .accordion .inputContent .slider_wrapper .slider .rangeInput input[type=range]::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: #046BD2;
  pointer-events: auto;
  -webkit-appearance: none;
  appearance: none;
}
.Container .bottom .accordion .inputContent .slider_wrapper .slider .rangeInput input[type=range]::-moz-range-thumb {
  height: 17px;
  width: 17px;
  border: none;
  border-radius: 50%;
  background: #046BD2;
  pointer-events: auto;
  -moz-appearance: none;
  background: #046BD2;
}
.Container .bottom .accordion .inputContent .slider_wrapper .range_searchBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  height: 35px;
}
.Container .bottom .accordion .inputContent .slider_wrapper .range_searchBtn button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #046BD2;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
}/*# sourceMappingURL=index.module.css.map */