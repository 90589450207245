@import '../../StyleHelpers/Variables.scss';



.Left_Container {
    background-color: #fff;
    border-radius: 5px;
    padding: 2.5rem;

    @include breakpoint(md) {
        padding: 0;
    }

    @include mediumScreen {
        padding: 1rem;
    }

    @include LargeScreen {
        padding: 1rem;
    }

    .navigation {
        text-decoration: none;
        color: #111;

        &:hover {
            color: $white_color;
        }
    }

    .profile_section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-bottom: 2px solid $base-color3;

        img {
            width: 100px;
            height: 100px;
            margin: 10px;
            border-radius: 50%;
            object-fit: cover;
        }

        .items {
            display: none;

            @include breakpoint(md) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            @include mediumScreen {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            h3 {
                color: #555;
                padding: 5px;
            }

            h4 {
                padding: 5px;
                font-size: 14px;
                color: #555;
            }
        }
    }

    .info_section {
        padding: 1rem 0;
        width: 100%;
        text-align: center;
        border-bottom: 2px solid $base-color3;

        h3 {
            color: $primary-color;
            font-size: 16px;
            padding: 10px 0;
        }


        ul {
            text-align: start;
            text-decoration: none;
            list-style: none;

            .edit_profile {
                display: none;

                @include breakpoint(md) {
                    display: contents;
                }
            }

            .active {
                background-color: $primary-color;
                color: $white_color;
                border-radius: 5px;
            }

            li {
                padding: 10px;
                margin-bottom: 0.2rem;
                text-decoration: none;
                list-style: none;
                cursor: pointer;

                &:hover {
                    background-color: $primary-color;
                    color: $white_color;
                    border-radius: 5px;
                }
            }


        }
    }

    .log_section {
        text-align: center;
        padding: 1rem 0;

        ul {
            text-align: start;
            text-decoration: none;
            list-style: none;

            li {
                padding: 10px;
                text-decoration: none;
                list-style: none;
                cursor: pointer;

                &:hover {
                    background-color: $primary-color;
                    color: $white_color;
                    border-radius: 5px;
                }
            }


        }
    }
}

.Right_Container {
    background-color: #fff;
    border-radius: 5px;
    padding: 3rem;
    border-left: 2px solid $base-color3;
    position: relative;

    @include breakpoint(md) {
        display: none;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
        margin-bottom: 2rem;

        .headerDiv {
            h2 {
                font-size: 1.8rem;
                font-weight: 500;
                line-height: 1.1;
            }
        }

        .headerBtnDiv {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: $primary-color;
                gap: 5px;
                background: none;
                border: none;
                font-size: medium;
                cursor: pointer;

                @include mediumScreen {
                    right: 20px;
                }

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }


    }

    .details {
        display: flex;
        place-content: center;
        flex-direction: column;

        .itemTitle {
            margin: 10px 5px;
            color: #555;
        }

        .detailItem {
            margin: 0.8rem .5rem;
            font-size: 14px;
            display: flex;

            .itemKey {
                font-weight: bold;
                color: gray;
                margin-right: 10px;
            }

            .itemValue {
                display: flex;
                align-items: center;
                gap: 1rem;
                font-weight: 300;

                .verifyIcon {
                    color: $green_color;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
    }
}




//----------Others profile---------


.top {
    padding: 10px 20px;
    display: flex;
    gap: 20px;

    @include breakpoint(md) {
        padding: 10px;
    }

    .left {
        flex: 6;
        padding: 20px 40px;
        border-radius: 5px;
        background-color: $base-color;

        @include breakpoint(md) {
            padding: 10px;
        }


        .title {
            font-size: 16px;
            color: $base_color5;
            margin: 10px 0;
        }


        .item {
            display: flex;
            gap: 60px;

            @include breakpoint(md) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .itemImg {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                object-fit: cover;
                margin-top: 20px;

            }

            .details {
                @include breakpoint(md) {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: flex-start;
                }

                .itemTitle {
                    color: #555;
                    margin-bottom: 0.5rem;

                    @include breakpoint(md) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 1rem;
                        font-size: 1.4rem;
                    }
                }

                .detailItem {
                    margin-bottom: 10px;
                    font-size: 14px;

                    .itemKey {
                        font-weight: bold;
                        color: gray;
                        margin-right: 5px;

                        @include breakpoint(md) {
                            font-size: 12px;
                        }
                    }

                    .itemValue {
                        font-weight: 300;

                        @include breakpoint(md) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }


}







//--------------End---------------