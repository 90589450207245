.page_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.page_wrapper .main {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 550px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .page_wrapper .main {
    margin-top: 80px;
  }
}
.page_wrapper .main .Wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f8f8f8;
  padding: 2rem 0;
}
.page_wrapper .main .Wrapper .productwrapper {
  width: 100%;
  min-height: 35rem;
  background-color: #f8f8f8;
}
.page_wrapper .main .Wrapper .productwrapper .container {
  margin: 0 3rem;
  display: block;
  padding: 0 20px;
}
@media (max-width: 550px) {
  .page_wrapper .main .Wrapper .productwrapper .container {
    margin: 0 0;
    padding: 0 10px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main .Wrapper .productwrapper .container {
    margin: 0 1rem;
  }
}
.page_wrapper .main .Wrapper .productwrapper .container .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0.5rem 0;
  border-bottom: 2px solid #ebebeb;
}
.page_wrapper .main .Wrapper .productwrapper .container .heading .left h2 {
  color: #666;
  font-weight: 600;
}
.page_wrapper .main .Wrapper .productwrapper .container .cardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  place-items: center;
  place-content: center;
  gap: 20px;
}
@media (max-width: 550px) {
  .page_wrapper .main .Wrapper .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main .Wrapper .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .page_wrapper .main .Wrapper .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .page_wrapper .main .Wrapper .productwrapper .container .cardWrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.page_wrapper .main .Wrapper .loadbtn {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 3rem 0 1rem 0;
}
.page_wrapper .main .Wrapper .loadbtn .inactiveBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #666;
  background-color: transparent;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.page_wrapper .main .Wrapper .loadbtn .inactiveBtn:hover {
  color: #666;
}
.page_wrapper .main .Wrapper .loadbtn .activeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #666;
  background-color: transparent;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.page_wrapper .main .Wrapper .loadbtn .activeBtn:hover {
  color: #046BD2;
}
.page_wrapper .main .Wrapper .loadbtn .activeBtn .icon {
  font-size: 20px;
  font-weight: 900;
}/*# sourceMappingURL=index.module.css.map */