.page_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.page_wrapper .main {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 550px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .page_wrapper .main {
    margin-top: 80px;
  }
}
.page_wrapper .main .productwrapper {
  padding: 1.5rem 0;
  min-height: 35rem;
  background-color: #f8f8f8;
}
.page_wrapper .main .productwrapper .container {
  margin: 0 4rem;
  display: block;
  padding: 0 20px;
}
@media (max-width: 550px) {
  .page_wrapper .main .productwrapper .container {
    margin: 0 0;
    padding: 0 10px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main .productwrapper .container {
    margin: 0 1rem;
  }
}
.page_wrapper .main .productwrapper .container .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0.5rem 0;
  border-bottom: 2px solid #ebebeb;
}
.page_wrapper .main .productwrapper .container .cardWrapper {
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  gap: 10px;
}/*# sourceMappingURL=index.module.css.map */