@import '../../StyleHelpers/Variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;

  .form_wrapper {
    width: 60%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;

    @include breakpoint(md) {
      width: 90%;
      flex-direction: column;
      box-shadow: none;
    }

    .form_container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      @include breakpoint(md) {
        flex-direction: column;
      }

      .otp_section {
        flex: 1;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        @include breakpoint(md) {
          padding: 20px;
          border-radius: 10px;
        }

        h1 {
          color: $primary_color;
          font-size: 1.8rem;
          margin-bottom: 20px;
        }

        form {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .input_div {
            display: flex;
            flex-direction: column;

            label {
              font-size: 14px;
              margin-bottom: 5px;
              color:#373737;
            }

            input {
              border: 1px solid rgba(149, 149, 149, 0.52);
              outline: none;
              border-radius: 8px;
              padding: 12px;
              background-color: transparent;
              font-size: 14px;
              color:#373737;
              transition: 0.3s;

              &:hover, &:focus {
                border-color: $primary_color;
              }
            }
          }

          button {
            background-color: $primary_color;
            color: white;
            border: none;
            border-radius: 8px;
            padding: 12px;
            font-size: 16px;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              background: $gradient_color;
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
            }
          }
        }
      }

      .right_section {
        flex: 1;
        background-color: $primary_color;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(md) {
          display: none;
        }

        .img_wrapper {
          padding: 2rem;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
