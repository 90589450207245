.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 200px;
    margin: 20px auto;
}

select {
    padding: 8px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: border-color 0.3s;
    
    option {
        padding: 5px;
        font-size: 12px;
    }
}

select:hover {
    border-color: #888;
}

select:focus {
    outline: none;
    border-color: #555;
}
