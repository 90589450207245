//colors
$gradient_color: linear-gradient(142deg, rgb(3, 116, 208, 1) 10%, rgb(0, 239, 189, 1) 100%);
$primary_color: #046BD2;
$secondary_color: #10E3C2;
$white_color: #fff;
$green_color: #03C03C;
$base_color: #ebebeb;
$base_color2: #373737;
//$base_color3: #f3f3f3;
$base_color3: #f8f8f8;
$base_color4: lightgrey;
$base_color5: #666;


//breakpoints

@mixin breakpoint($point) {
  @if $point ==md {
    @media(max-width:550px) {
      @content;
    }
  }
}

@mixin mediumScreen {
  @media (min-width: 550px) and (max-width: 768px) {
    @content;
  }
}

@mixin extraMediumScreen {
  @media (min-width: 768px) and (max-width: 900px) {
    @content;
  }
}

@mixin LargeScreen {
  @media (min-width: 900px) and (max-width: 1024px) {
    @content;
  }
}

@mixin extraLargeScreen {
  @media (min-width:900px) and (max-width: 1800px) {
    @content;
  }
}