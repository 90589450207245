@import '../../../StyleHelpers/Variables.scss';

/* user profile styling */

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .main {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            margin-top: 120px;
        }

        @include mediumScreen {
            margin-top: 120px;
        }

        @include LargeScreen {
            margin-top: 80px;
        }

        // -- User Profile Styling --

        .Main_Container {
            min-height: 100vh;
            display: flex;
            justify-content: center;
            background-color: #fff;
            padding: 0 3rem;
            overflow: hidden;
            gap: 5px;

            @include breakpoint(md) {
                padding: 0.5rem;
            }

            .Left_Section {
                flex: 2;

                @include breakpoint(md) {
                    flex: 1;
                }

                @include mediumScreen {
                    flex: 1;
                }
            }

            .Right_Section {
                flex: 6;

                @include breakpoint(md) {
                    display: none;
                }

                @include mediumScreen {
                    display: none;
                }


            }
        }


        //------review container styling-------

        .popup {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            z-index: 999;

            .content {
                position: absolute;
                top: 55%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #fff;
                padding: 20px;
                border-radius: 10px;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.36);

                .Title {
                    margin: 10px 0;

                    h3 {
                        font-size: 16px;
                        font-weight: 600;
                    }
                }

                .ratingstars {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 26px;
                    margin: 15px 0;
                }

                .textBox {
                    width: 100%;
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    textarea {
                        width: 90%;
                        border: none;
                        padding: 10px 5px;
                        border-radius: 8px;
                        font-size: 14px;
                        border: none;
                        outline: none;
                        background-color: $base_color3;
                        border-left: 4px solid $primary_color;
                    }
                }

                .btnBox {
                    display: flex;
                    margin: 10px 0;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    button {
                        padding: 10px 15px;
                        background-color: #fff;
                        color: $primary-color;
                        border: 1px solid $primary-color ;
                        outline: none;
                        border-radius: 5px;
                        font-size: 14px;
                        font-weight: 600;
                        cursor: pointer;

                        &:hover {
                            background-color: $primary-color;
                            color: #fff;
                            border: none;
                        }
                    }
                }
            }
        }

        //-------- End ---------

        .ad_container {
            min-height: 240px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            place-items: center;
            gap: 20px;
            margin-bottom: 1rem;

            @include breakpoint(md) {
                grid-template-columns: 1fr 1fr;
                padding: 5px;
                gap: 10px 5px;
            }

            @include LargeScreen {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 20px;
            }

            @include mediumScreen {
                grid-template-columns: 1fr 1fr 1fr;
                padding: 20px;
                gap: 20px 20px;
            }
        }

        .ad_container,
        .review_container {
            padding: 20px;
        }

        //----- Review Styling -----
        .review_container {
            display: flex;
            gap: 10px;
            min-height: 510px;
            padding: 30px 20px;
            margin: 10px 20px;
            border-top: 1px solid $base-color4;

            @include breakpoint(md) {
                flex-direction: column;
                padding: 1rem;
                margin: 0;
            }

            @include mediumScreen {
                flex-direction: column;
            }

            @include LargeScreen {}

            .left {
                flex: 2;
                height: 100%;
                padding: 20px;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include breakpoint(md) {
                    flex: 1;
                }

                @include mediumScreen {
                    flex: 1;
                }

                .top {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .Rating_wrap {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        margin: 10px;

                        .rating {
                            h1 {
                                font-size: 38px;
                                font-weight: 900
                            }
                        }

                        .Icon_wrap {
                            display: flex;
                            flex-direction: column;

                            .ratingIcon {
                                color: goldenrod;
                                font-size: 18px;
                            }

                            p {
                                font-size: 12px;
                                color: $base-color2;
                            }
                        }
                    }
                }

                .bottom {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-top: 1px solid $base_color3;

                    .title {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin: 10px 0;
                        text-align: start;

                        h4 {
                            margin: 0 10px;
                        }
                    }

                    .Btn_wrap {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 10px 0;

                        .WriteBtn {
                            background-color: $base-color2;
                            color: $base-color;
                            border: none;
                            outline: none;
                            border-radius: 3px;
                            padding: 10px 20px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .right {
                flex: 6;
                border: 3px solid $base_color3;
                border-radius: 5px;
                overflow: hidden;

                @include breakpoint(md) {
                    flex: 1;
                }

                @include mediumScreen {
                    flex: 1;
                }

                .top {
                    text-align: start;
                    padding: 10px;
                    background-color: $base-color3;

                    h3 {
                        font-size: 18px;
                        font-weight: 600;
                    }
                }

                .bottom {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    overflow: scroll;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .none_wrap {
                        width: 100%;
                        display: flex;
                        padding: 10px 0 50px 0;
                        place-content: center;
                        color: $primary_color;
                    }

                    .reviews_wrap {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        border-bottom: 1px solid $base-color3;

                        .Image_wrap {
                            width: 10%;
                            height: 60px;
                            margin: 10px;
                            display: flex;
                            align-items: center;
                            place-content: center;

                            img {
                                width: 60px;
                                height: 100%;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }

                        .Det_wrap {
                            width: 90%;
                            margin: 10px;
                            display: flex;
                            flex-direction: column;

                            .Id {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }

                            .Id,
                            .Message {
                                margin: 5px 0;

                                label {
                                    font-size: 14px;
                                    font-weight: 600;
                                }

                                p {
                                    font-size: 12px;
                                    font-weight: 500;
                                }

                                span {
                                    color: $primary_color;
                                    cursor: pointer;
                                }

                                .Reply {
                                    margin-top: 5px;
                                    border-top: 2px solid $base-color4;
                                    padding: 5px;
                                    display: flex;
                                    flex-direction: column;

                                    span {
                                        margin: 0 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        //----- End Review Styling -----
    }
}