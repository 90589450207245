.carouserl_wrap {
  width: 100%;
  /*----- control btn -------*/
}
.carouserl_wrap .control_btn .next,
.carouserl_wrap .control_btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 60px;
  background-color: rgba(102, 102, 102, 0.3568627451);
  border-radius: 50%;
  border: none;
  transform: 0.5s;
  z-index: 5;
}
.carouserl_wrap .control_btn .next i,
.carouserl_wrap .control_btn .prev i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  transition: 0.5s;
  color: #fff;
}
.carouserl_wrap .control_btn .prev {
  left: 1%;
}
.carouserl_wrap .control_btn .next {
  right: 1%;
}

/*----- control btn -------*//*# sourceMappingURL=index.module.css.map */