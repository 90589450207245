.single {
  display: flex;
  width: 100%;
}
.single .singleContainer {
  flex: 6;
}
.single .singleContainer .top {
  padding: 20px;
  display: flex;
  gap: 20px;
}
.single .singleContainer .top .left {
  flex: 1;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  border-radius: 5px;
  position: relative;
}
.single .singleContainer .top .left .editButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 20px;
  font-size: 12px;
  color: #fff;
  background-color: #046BD2;
  cursor: pointer;
  border-radius: 0px 0px 0px 5px;
}
.single .singleContainer .top .left .item {
  display: flex;
  gap: 30px;
  padding: 20px 0;
}
.single .singleContainer .top .left .item .itemImg {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single .singleContainer .top .left .item .itemImg .squareImg {
  width: 200px;
  height: 200px;
  border-radius: 0%;
}
.single .singleContainer .top .left .item .itemImg img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}
.single .singleContainer .top .left .item .AdsImg {
  flex: 6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.single .singleContainer .top .left .item .AdsImg img {
  width: 100%;
  height: 150px;
}
.single .singleContainer .top .left .item .details {
  flex: 6;
}
.single .singleContainer .top .left .item .details .itemTitle {
  margin-bottom: 10px;
  color: #555;
}
.single .singleContainer .top .left .item .details .detailItem {
  margin-bottom: 10px;
  font-size: 14px;
}
.single .singleContainer .top .left .item .details .detailItem .itemKey {
  font-weight: bold;
  color: gray;
  margin-right: 5px;
}
.single .singleContainer .top .left .item .details .detailItem .itemValue {
  font-weight: 300;
}
.single .singleContainer .top .left .item .details .detailItem .featureDetails {
  display: flex;
  gap: 5px;
  margin: 10px;
}
.single .singleContainer .bottom {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 10px 20px;
}
.single .singleContainer .title {
  font-size: 16px;
  color: lightgray;
  margin-bottom: 20px;
}/*# sourceMappingURL=Style.module.css.map */