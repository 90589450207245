.page_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.page_wrapper .main {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 550px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .page_wrapper .main {
    margin-top: 80px;
  }
}
.page_wrapper .main .productwrapper {
  padding: 1.5rem 0;
  min-height: 35rem;
  background-color: #f8f8f8;
}
.page_wrapper .main .productwrapper .container {
  margin: 0 4rem;
  display: block;
  padding: 0 20px;
}
@media (max-width: 550px) {
  .page_wrapper .main .productwrapper .container {
    margin: 0 0;
    padding: 0 10px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main .productwrapper .container {
    margin: 0 1rem;
  }
}
.page_wrapper .main .productwrapper .container .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0.5rem 0;
  border-bottom: 2px solid #ebebeb;
}
.page_wrapper .main .productwrapper .container .cardWrapper {
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  gap: 10px;
}
.page_wrapper .main .productwrapper .container .imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_wrapper .main .productwrapper .container .imgWrapper img {
  width: 350px;
  height: 450px;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 550px) {
  .page_wrapper .main .productwrapper .container .imgWrapper img {
    width: 250px;
    height: 450px;
  }
}
.page_wrapper .main .productwrapper .container .modal_wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  padding: 20px;
}

.modal_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  padding: 25px;
}
.modal_container .closeIcon_wrapper {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 50px;
}
.modal_container .closeIcon_wrapper .back_logo {
  padding: 10px;
}
.modal_container .left_container {
  margin-right: 20px;
  max-width: 200px;
  overflow: hidden;
}
.modal_container .left_container img {
  width: 15rem;
  height: 12rem;
}
.modal_container .right_container {
  display: flex;
  flex-direction: column;
}
.modal_container .time_shower {
  position: absolute;
  bottom: 4px;
  right: 5px;
  color: blue;
  font-size: 12px;
}
@media only screen and (max-width: 768px) {
  .modal_container {
    width: 340px;
    margin: 0 auto;
  }
}/*# sourceMappingURL=index.module.css.map */