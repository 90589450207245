.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}
.container .form_wrapper {
  width: 60%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
}
@media (max-width: 550px) {
  .container .form_wrapper {
    width: 90%;
    flex-direction: column;
    box-shadow: none;
  }
}
.container .form_wrapper .form_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 550px) {
  .container .form_wrapper .form_container {
    flex-direction: column;
  }
}
.container .form_wrapper .form_container .otp_section {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
@media (max-width: 550px) {
  .container .form_wrapper .form_container .otp_section {
    padding: 20px;
    border-radius: 10px;
  }
}
.container .form_wrapper .form_container .otp_section h1 {
  color: #046BD2;
  font-size: 1.8rem;
  margin-bottom: 20px;
}
.container .form_wrapper .form_container .otp_section form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.container .form_wrapper .form_container .otp_section form .input_div {
  display: flex;
  flex-direction: column;
}
.container .form_wrapper .form_container .otp_section form .input_div label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #373737;
}
.container .form_wrapper .form_container .otp_section form .input_div input {
  border: 1px solid rgba(149, 149, 149, 0.52);
  outline: none;
  border-radius: 8px;
  padding: 12px;
  background-color: transparent;
  font-size: 14px;
  color: #373737;
  transition: 0.3s;
}
.container .form_wrapper .form_container .otp_section form .input_div input:hover, .container .form_wrapper .form_container .otp_section form .input_div input:focus {
  border-color: #046BD2;
}
.container .form_wrapper .form_container .otp_section form button {
  background-color: #046BD2;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}
.container .form_wrapper .form_container .otp_section form button:hover {
  background: linear-gradient(142deg, rgb(3, 116, 208) 10%, rgb(0, 239, 189) 100%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
.container .form_wrapper .form_container .right_section {
  flex: 1;
  background-color: #046BD2;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 550px) {
  .container .form_wrapper .form_container .right_section {
    display: none;
  }
}
.container .form_wrapper .form_container .right_section .img_wrapper {
  padding: 2rem;
}
.container .form_wrapper .form_container .right_section .img_wrapper img {
  width: 100%;
  height: auto;
}/*# sourceMappingURL=index.module.css.map */