@import "../../../StyleHelpers/Variables.scss";

.home_container {
  //height: 100vh;
  width: 100%;
  margin-top: 5rem;
  position: relative;

  @include breakpoint(md) {
    margin-top: 7rem;
  }

  @include mediumScreen {
    margin-top: 110px;
  }

  .Main_container {
    display: flex;
    justify-content: center;
    gap: 0.2rem;
    min-height: 510px;
    overflow: hidden;
    padding: 0 60px;

    @include breakpoint(md) {
      min-height: 510px;
      padding: 0;
    }

    @include mediumScreen {
      min-height: 510px;
      padding: 0;
    }

    @include extraMediumScreen {
      padding: 0;
    }

    @include LargeScreen {
      padding: 0 40px;
    }
    /* LeftCategory.module.scss */
    .left_cat {
      opacity: 0;
      visibility: hidden;
      max-height: 0;
      overflow: hidden;
      transform: translateY(-30px); /* Adjusted for top-to-bottom animation */
      transition: opacity 0.5s ease-out, transform 0.5s ease-out,
        max-height 0.5s ease-out, visibility 0s 0.5s;
      height: auto;
    }

    .left_catenter {
      opacity: 1;
      visibility: visible;
      max-height: 100%; /* Set to a value that accommodates the content */
      transform: translateY(0);
      transition-delay: 0s, 0s, 0s, 0s;
    }

    .Left {
      background-color: $base_color3;
      width: 21%;
      max-width: 340px;
      height: 1750px;
      overflow-y: auto;

      @include breakpoint(md) {
        display: none;
      }

      @include mediumScreen {
        display: none;
      }

      @include extraMediumScreen {
        display: none;
      }

      @include LargeScreen {
        width: 25%;
      }
    }

    .Right {
      width: 77%;
      max-width: 1080px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include breakpoint(md) {
        width: 100%;
      }

      @include mediumScreen {
        width: 100%;
      }

      @include extraMediumScreen {
        width: 100%;
      }

      @include LargeScreen {
        width: 70%;
      }

      .cardWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $base_color3;
        margin-top: 0.2rem;
        padding: 2rem 0;

        .loadbtn {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: center;
          gap: 20px;
          padding: 3rem 0 1rem 0;

          .inactiveBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: $base_color5;
            background-color: transparent;
            padding: 10px;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.2;
            border-radius: 5px;
            border: none;
            cursor: pointer;

            &:hover {
              color: $base_color5;
            }
          }

          .activeBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: $base_color5;
            background-color: transparent;
            padding: 10px;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.2;
            border-radius: 5px;
            border: none;
            cursor: pointer;

            &:hover {
              color: $primary_color;
            }

            .icon {
              font-size: 20px;
              font-weight: 900;
            }
          }
        }
      }
    }
  }
  .Reffermodal_container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); // semi-transparent background
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; // ensure it appears on top
  }
}
