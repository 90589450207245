.form_wrapper {
  box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.31);
  background-color: aliceblue;
  width: 50rem;
  min-height: 30rem;
}
@media (max-width: 550px) {
  .form_wrapper {
    width: 100vw;
    min-height: 100vh;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .form_wrapper {
    box-shadow: none;
  }
}
.form_wrapper .form_container {
  display: flex;
  min-height: 30rem;
  overflow: hidden;
}
@media (max-width: 550px) {
  .form_wrapper .form_container {
    min-height: 100vh;
    transition: 0.5s ease-in-out;
  }
}
.form_wrapper .form_container .left_section {
  width: 100%;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  position: relative;
}
@media (min-width: 550px) and (max-width: 768px) {
  .form_wrapper .form_container .left_section {
    padding: 40px 60px;
  }
}
.form_wrapper .form_container .left_section .Step_Status {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #046BD2;
}
.form_wrapper .form_container .left_section h1 {
  color: #046BD2;
}
.form_wrapper .form_container .left_section p {
  font-size: 12px;
}
.form_wrapper .form_container .left_section p span {
  color: #046BD2;
  font-size: 0.8rem;
  font-weight: 400;
}
.form_wrapper .form_container .left_section .resendBtn {
  background: none;
  border: none;
  outline: none;
  color: #046BD2;
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 1rem;
  letter-spacing: 0;
  cursor: pointer;
}
.form_wrapper .form_container .left_section .resendBtn:hover {
  background: none;
}
.form_wrapper .form_container .left_section .error_para {
  color: red;
  font-size: 13px;
  text-align: center;
}
.form_wrapper .form_container .left_section .login_Details h1 {
  color: #046BD2;
  font-size: 20px;
}
.form_wrapper .form_container .left_section .login_Details p {
  padding: 5px 0px;
  font-size: 12px;
}
.form_wrapper .form_container .left_section form .error {
  font-size: 12px;
  color: red;
  margin-top: 5px;
}
.form_wrapper .form_container .left_section form .terms {
  color: #111;
  margin: 5px 0;
}
.form_wrapper .form_container .left_section form .terms .navigation {
  text-decoration: none;
  color: #046BD2;
  padding: 0 5px;
}
.form_wrapper .form_container .left_section form .input_div {
  padding: 2px;
  display: flex;
  gap: 10px;
}
@media (max-width: 550px) {
  .form_wrapper .form_container .left_section form .input_div {
    flex-direction: column;
    transition: 0.3s ease-all;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .form_wrapper .form_container .left_section form .input_div {
    flex-direction: column;
    transition: 0.3s ease-all;
  }
}
.form_wrapper .form_container .left_section form .input_div div {
  width: 100%;
  display: flex;
  gap: 6px;
  flex-direction: column;
}
.form_wrapper .form_container .left_section form .input_div div .error {
  font-size: 12px;
  color: red;
}
.form_wrapper .form_container .left_section form .input_div div span {
  color: #046BD2;
  font-size: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.form_wrapper .form_container .left_section form .input_div div span:hover {
  color: #10E3C2;
}
.form_wrapper .form_container .left_section form .input_div div input {
  border-color: rgba(149, 149, 149, 0.52);
  outline: none;
  border-width: 0.3px;
  border-radius: 5px;
  padding: 14px 10px;
  background-color: #ebebeb;
}
.form_wrapper .form_container .left_section form .input_div div .dobInput {
  width: 100%;
  border-color: rgba(149, 149, 149, 0.52);
  outline: none;
  border-width: 0.3px;
  border-radius: 5px;
  padding: 13px 0;
  background-color: #ebebeb;
}
.form_wrapper .form_container .left_section form .input_div div .input_Wrap {
  position: relative;
}
.form_wrapper .form_container .left_section form .input_div div .input_Wrap .eye_icon {
  position: absolute;
  right: 15px;
  top: 50%;
  font-size: 16px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
}
.form_wrapper .form_container .left_section form .input_div div .input_Wrap .eye_icon:hover {
  color: #046BD2;
}
.form_wrapper .form_container .left_section form .input_div div label {
  font-size: 12px;
}
.form_wrapper .form_container .left_section form button {
  cursor: pointer;
  margin-top: 5px;
  background-color: #046BD2;
  width: 100%;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 14px 0px;
  font-size: 15px;
}
.form_wrapper .form_container .left_section form button:hover {
  background: linear-gradient(142deg, rgb(3, 116, 208) 10%, rgb(0, 239, 189) 100%);
}
.form_wrapper .form_container .left_section .additional_options {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
@media (max-width: 550px) {
  .form_wrapper .form_container .left_section .additional_options {
    flex-direction: row;
    justify-content: space-between;
  }
}
.form_wrapper .form_container .left_section .additional_options p {
  font-size: 13px;
}
.form_wrapper .form_container .left_section .additional_options .navigation {
  text-decoration: none;
}
.form_wrapper .form_container .left_section .Google_authentication {
  padding-top: 20px;
}
.form_wrapper .form_container .left_section .Google_authentication .break {
  color: #373737;
  display: flex;
  font-size: 12px;
  justify-content: space-evenly;
  align-items: center;
}
.form_wrapper .form_container .left_section .Google_authentication .break div {
  background: rgba(0, 0, 0, 0.511);
  height: 0.5px;
  width: 8rem;
}
.form_wrapper .form_container .left_section .Google_authentication .GoogleButton {
  padding: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
}
.form_wrapper .form_container .left_section .Google_authentication button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: white;
  border: none;
  padding: 2rem 3rem;
  font-size: 15px;
}
.form_wrapper .form_container .left_section .Google_authentication button:hover {
  background-color: #ebebeb;
  border-radius: 50px;
}
.form_wrapper .form_container .right_section {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #046BD2;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
@media (max-width: 550px) {
  .form_wrapper .form_container .right_section {
    display: none;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .form_wrapper .form_container .right_section {
    display: none;
  }
}
.form_wrapper .form_container .right_section .img_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form_wrapper .form_container .right_section .img_wrapper img {
  width: 250px;
  height: 400px;
}/*# sourceMappingURL=index.module.css.map */