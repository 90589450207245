@import '../../../StyleHelpers/Variables.scss';

.home {
    display: flex;

    .homeContainer {
        flex: 6;
        background-color: #fff;
    }

    .widgets,
    .charts {
        display: flex;
        padding: 20px;
        gap: 20px;
    }

    .charts {
        padding: 5px 20px;
    }

    .listContainer {
        padding: 20px;
        margin: 20px;
        border-radius: 4px;
        box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.57);
        -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.57);

        .listTitle{
            font-weight: 500;
            margin-bottom: 20px;
        }
    }
}