@import '../../../StyleHelpers/Variables.scss';


.products {
    width: 100%;
    max-width: 250px;
    min-width: 140px;
    transition: box-shadow .35s ease;
    background-color: #fff;
    margin-bottom: 0 !important;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    }

    &:hover,
    &:focus {
        .productmedia {
            .productAction {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .productmedia {
        position: relative;
        display: block;
        background-color: #fff;
        margin-bottom: 1rem;
        border-radius: 5px;
        padding: 0.2rem;
        overflow: hidden;


        .productlabel {
            position: absolute;
            z-index: 1;
            top: .5rem;
            left: .5rem;
            font-weight: 300;
            font-size: 0.8rem;
            line-height: 1rem;
            letter-spacing: -.01em;
            padding: 0.4rem 0.6rem;
            min-width: 40px;
            text-align: center;
            color: #fff;
            background-color: $primary_color;


            @include breakpoint(md) {
                top: .5rem;
                left: .5rem;
                font-size: 0.6rem;
                padding: 0.2rem 0.4rem;
            }
        }

        .productImage {
            display: block;
            width: 100%;
           min-height: 180px;
           max-height: 230px;
            border-style: none;
            object-fit: cover;
            border-radius: 5px;
            background-color: #fff;

            @include breakpoint(md) {
                height: 180px;
            }

        }

        .productAction {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.95);
            z-index: 10;
            transition: all .35s ease;
            opacity: 0;
            visibility: hidden;
            padding: .6rem .4rem;
            border-top: .1rem solid #ebebeb;
            border-bottom: .1rem solid #ebebeb;
            transform: translateY(100%);

            @include breakpoint(md) {}

            @include mediumScreen {}

            @include LargeScreen {}


            span {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                text-align: center;
                color: #3399ff;
                font-weight: 400;
                font-size: 14px;

                @include breakpoint(md) {
                    flex-direction: column;
                    font-size: 12px;
                }

                i {
                    display: flex;
                    align-items: center;

                    @include breakpoint(md) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .productbody {
        position: relative;
        padding: 0.8rem;
        transition: all .35s ease;

        .product_cat {
            color: #666;
            font-weight: 300;
            font-size: 0.8rem;
            line-height: 1.2;
            overflow: hidden; 
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .product_title {
            margin-bottom: .2rem;

            h3 {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.25;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .product_price {
            display: flex;
            align-items: center;
            flex-flow: wrap;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.25;
            color: #3399ff;
            margin-bottom: 0.2rem;
        }
    }
}