/*--------Left Container---------*/
.LeftContainer {
  background-color: #f8f8f8;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 550px) {
  .LeftContainer {
    display: none;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .LeftContainer {
    display: none;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .LeftContainer {
    width: 100%;
  }
}
.LeftContainer .navigation {
  text-decoration: none;
  color: #111;
}
.LeftContainer .title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  color: #fff;
  text-transform: none;
  background-color: #046BD2;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
@media (min-width: 900px) and (max-width: 1024px) {
  .LeftContainer .title {
    padding: 0.8rem;
  }
}
.LeftContainer .title span {
  font-size: 1rem;
  font-weight: 500;
}
.LeftContainer .title i {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.LeftContainer .box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 20px;
}
.LeftContainer .box img {
  width: 30px;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.LeftContainer .box span {
  margin: 0px 20px;
  text-transform: capitalize;
  text-align: start;
}
.LeftContainer .box:hover {
  background-color: #046BD2;
  cursor: pointer;
}
.LeftContainer .box:hover span {
  color: #fff;
}

/*--------End Left Container---------*/
/*--------Right Container---------*/
.RContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #f8f8f8;
  animation: move 2s 1 normal;
}
@keyframes move {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(1%);
  }
}
.RContainer .title {
  margin-top: 20px;
}
.RContainer .title h3 {
  font-size: 24px;
  font-weight: 600;
  color: #666;
}
@media (max-width: 550px) {
  .RContainer .title h3 {
    font-size: 20px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .RContainer .title h3 {
    font-size: 24px;
  }
}
.RContainer .box_wrapper {
  width: 100%;
  min-height: 260px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  place-content: center;
  place-items: center;
  grid-gap: 20px;
  padding: 40px 0px;
}
@media (max-width: 550px) {
  .RContainer .box_wrapper {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    width: 100%;
    padding: 20px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .RContainer .box_wrapper {
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    width: 100%;
    padding: 20px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .RContainer .box_wrapper {
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    width: 100%;
    padding: 20px 0;
    gap: 10px;
  }
}
.RContainer .box_wrapper .item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  background-color: transparent;
}
.RContainer .box_wrapper .item .box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  text-align: center;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
@media (max-width: 550px) {
  .RContainer .box_wrapper .item .box {
    width: 90px;
    height: 90px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .RContainer .box_wrapper .item .box {
    width: 90px;
    height: 90px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .RContainer .box_wrapper .item .box {
    width: 90px;
    height: 90px;
  }
}
.RContainer .box_wrapper .item .box img {
  width: 80%;
  height: 80%;
  -o-object-fit: contain;
     object-fit: contain;
}
.RContainer .box_wrapper .item h6 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: #666;
  cursor: default;
}
@media (max-width: 550px) {
  .RContainer .box_wrapper .item h6 {
    display: none;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .RContainer .box_wrapper .item h6 {
    font-size: 12px;
  }
}

/*--------End Right Container---------*/
.Tcontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40;
  background-color: #046BD2;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  box-shadow: 1px 7px 5px -3px rgb(194, 183, 194);
  color: white;
}
@media (max-width: 550px) {
  .Tcontainer {
    display: none;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .Tcontainer {
    font-size: 10px;
  }
}
.Tcontainer .categorylist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  flex-direction: row;
  color: white;
}
.Tcontainer .categorylist .category_btn {
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}
@media (max-width: 550px) {
  .Tcontainer .categorylist .category_btn {
    display: none;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .Tcontainer .categorylist .category_btn {
    display: none;
  }
}
.Tcontainer .categorylist .category_btn button {
  border: none;
  outline: none;
  background-color: inherit;
  font: inherit;
  margin: inherit;
  padding: inherit;
  cursor: pointer;
  color: inherit;
}
.Tcontainer .categorylist .category_btn .iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Tcontainer .categorylist ul {
  display: flex;
  flex-direction: row;
  gap: 20px;
  cursor: pointer;
}
.Tcontainer .categorylist ul li {
  list-style-type: none;
}/*# sourceMappingURL=Style.module.css.map */