.page_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.page_wrapper .main {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 550px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .page_wrapper .main {
    margin-top: 120px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .page_wrapper .main {
    margin-top: 80px;
  }
}/*# sourceMappingURL=index.module.css.map */