@import '../../../StyleHelpers/Variables.scss';


.content {
    width: 100%;
    height: 100%;

    .contentWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 1rem;

        @include breakpoint(md) {
            padding: 2rem 1rem;
            flex-direction: column;
            align-items: flex-start;
        }

        @include mediumScreen {
            flex-direction: column;
            align-items: flex-start;
        }

        .top {
            margin: 20px 0;
        }

        .top,
        .bottom {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include breakpoint(md) {
                padding: 2rem 1rem;
                flex-direction: column;
                align-items: center;
            }
    
            @include mediumScreen {
                flex-direction: column;
                align-items: center;
            }

            .title {
                padding: 0 10px;
                display: flex;
                align-items: flex-start;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                width: 100%;

                .active {
                    width: 100%;
                    font-weight: 700;
                    color: $primary_color;
                }

                li {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    cursor: pointer;

                    &:hover {
                        width: 100%;
                        background-color: $base_color3;
                        color: #111;
                        border-radius: 3px;
                    }
                }
            }
        }
    }


}