@import '../../../StyleHelpers/Variables.scss';
/* Main content wrapper */
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

 

}

/* Navbar container */
.navbarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Space between navbar and breadcrumbs */
}

/* Breadcrumbs container, placed below the navbar */
.breadcrumbContainer {
  display: flex;
  width: 100%;
  margin-top: 70px; /* Default space between navbar and breadcrumbs */

  /* Include the breakpoint mixin for medium devices */
  @include breakpoint(md) {
    margin-top: 100px; /* Increase the space for medium devices */
  }

  /* Optional: if you need further responsiveness */
  @include breakpoint(lg) {
    margin-top: 120px; /* Further increase space for larger screens */
  }
}

/* Toggle buttons container */
.toggleNavbarContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px; /* Space between toggle buttons and content */
}

/* Navbar items (toggle buttons) */
.navbarItem {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  position: relative;
  transition: color 0.3s ease, font-weight 0.3s ease;

  @include breakpoint(md){
    padding: 14px 13px;
    font-size: 17px;
  }
  
  &:hover {
    color: #007bff; /* Hover color */
  }

  /* Active button with blue bottom border */
  &.active {
    color: #000;
    font-weight: bold;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: blue;
      transition: transform 0.3s ease;
      transform: scaleX(1);
    }
  }

  /* Inactive button border */
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: blue;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
}

/* Main content container */
.contentContainer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/* Option 1 and 2 components */
// .componentOption1{
//   width: 100%;
//   max-width: 90%;
//   padding: 20px;
//   background-color: #f5f5f5;
//   border-radius: 8px;
//   box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
//   transition: opacity 0.3s ease-in-out;
// }
