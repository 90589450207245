@import '../../../StyleHelpers/Variables.scss';

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .main {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            margin-top: 120px;
        }

        @include mediumScreen {
            margin-top: 120px;
        }

        @include LargeScreen {
            margin-top: 80px;
        }

        .container {
            position: relative;
            min-height: 510px;
            display: flex;
            justify-content: center;
            padding: 0 4rem;


            @include breakpoint(md) {
                padding: 0 0rem;
                flex-direction: column-reverse;
            }

            @include mediumScreen {
                padding: 0 1rem;
                flex-direction: column-reverse;
            }

            .To_top {
                position: fixed;
                bottom: 1rem;
                right: 2rem;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 2rem;
                font-weight: 600;
                background-color: $primary_color;
                border-radius: 5px;
                color: #fff;
            }

            .left {
                flex: 2;
            }

            .right {
                flex: 6;

                .content_wrapper {
                    padding: 3rem 1rem;

                    @include breakpoint(md) {
                        padding: 2rem 1rem;
                    }

                    @include mediumScreen {}

                    @include LargeScreen {}

                    .container_wrap {
                        width: 100%;

                        .row {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: wrap;

                            @include breakpoint(md) {
                                flex-direction: column;
                            }

                            @include mediumScreen {
                                flex-direction: column;
                            }

                            .top,
                            .bottom {
                                display: flex;
                                flex: 1;
                                flex-direction: column;
                                padding: 10px;

                                .bold {
                                    font-weight: 500;
                                }

                                .links {
                                    color: $primary_color;
                                    margin-left: .5rem;
                                    text-decoration: none;
                                }

                                .point {
                                    margin: 0 .2rem;
                                    text-align: center;
                                }

                                .title {
                                    margin-bottom: 2rem;

                                    h2 {
                                        margin-bottom: .8rem;
                                        font-size: 2rem;
                                        font-weight: 500;
                                        line-height: 1;
                                    }

                                    p {
                                        font-size: 1rem;
                                        font-weight: 300;
                                        font-family: 'Poppins';
                                        letter-spacing: 0;
                                        color: #777;
                                    }
                                }

                                .subtitle {
                                    margin-bottom: 1rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;

                                    span {
                                        text-align: center;
                                        margin-right: 0.5rem;
                                    }

                                    h3 {
                                        font-size: 1rem;
                                        font-weight: 600;
                                        line-height: 0.8rem;
                                    }
                                }

                                .subdata {
                                    p {
                                        margin-bottom: 1rem;
                                        font-size: 14px;
                                        font-weight: 300;
                                    }
                                }

                                .step {
                                    margin-top: .5rem;
                                    margin-bottom: 1rem;

                                    .step_title {
                                        border-bottom: 1px solid $base_color4;
                                        margin: 1rem 0;

                                        h5 {
                                            font-weight: 500;
                                        }
                                    }

                                    .step_wrapper {
                                        display: flex;
                                        gap: 10px;
                                        width: 100%;

                                        @include breakpoint(md) {
                                            flex-direction: column;
                                        }

                                        @include mediumScreen {
                                            flex-direction: column;
                                        }

                                        .step_content {
                                            flex: 1;
                                            display: flex;
                                            align-items: flex-start;
                                            justify-content: center;
                                            flex-direction: column;

                                            p {
                                                margin-bottom: 1rem;
                                                font-size: 14px;
                                                font-weight: 300;
                                            }

                                            @include breakpoint(md) {
                                                margin-bottom: 0.5rem;
                                            }

                                            @include mediumScreen {
                                                margin-bottom: 0.5rem;
                                            }
                                        }

                                        .step_img {
                                            flex: 1;
                                            display: flex;
                                            align-items: flex-start;
                                            justify-content: flex-start;

                                            img {
                                                width: 100%;
                                                height: 200px;
                                                object-fit: contain;
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}