@import "../../StyleHelpers/Variables.scss";

.outer_container {
  display: flex;
  justify-content: center;
  padding: 20px;

  .conatiner {
    width: 100%;
    max-width: 800px; /* You can adjust this based on your preference */
    padding: 20px;
    .reward_container {
      padding: 16px;
      background-color: #f5f5f5;
      border-radius: 8px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      transition: opacity 0.3s ease-in-out;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: start;
      gap: 50px;
      margin-block-end: 10px;
      .reward_container > div {
        flex: 1; /* This makes each child div take equal space */
        text-align: center; /* Centers the content */
        padding: 10px;
        word-break: break-word; /* Handle long text like "Reward Pending" */
      }

      @include breakpoint(md) {
        gap: 0;
      }
      @include mediumScreen {
        gap: 100px;
      }
    }
    button {
      padding: 8px 16px;
      background-color:$primary_color;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    
    button:hover {
      background-color: #45a049;
    }
  }
}
