.outer_container {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.outer_container .conatiner {
  width: 100%;
  max-width: 800px; /* You can adjust this based on your preference */
  padding: 20px;
}
.outer_container .conatiner .reward_container {
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: start;
  gap: 50px;
  margin-block-end: 10px;
}
.outer_container .conatiner .reward_container .reward_container > div {
  flex: 1; /* This makes each child div take equal space */
  text-align: center; /* Centers the content */
  padding: 10px;
  word-break: break-word; /* Handle long text like "Reward Pending" */
}
@media (max-width: 550px) {
  .outer_container .conatiner .reward_container {
    gap: 0;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .outer_container .conatiner .reward_container {
    gap: 100px;
  }
}
.outer_container .conatiner button {
  padding: 8px 16px;
  background-color: #046BD2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.outer_container .conatiner button:hover {
  background-color: #45a049;
}/*# sourceMappingURL=index.module.css.map */