@import '../../../StyleHelpers/Variables.scss';

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .main {
        width: 100%;
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            margin-top: 120px;
        }

        @include mediumScreen {
            margin-top: 120px;
        }

        @include LargeScreen {
            margin-top: 80px;
        }

        .productwrapper {
            padding: 1.5rem 0;
            min-height: 35rem;
            background-color: #f8f8f8;

            .container {
                margin: 0 4rem;
                display: block;
                padding: 0 20px;

                @include breakpoint(md) {
                    margin: 0 0;
                    padding: 0 10px;
                }

                @include mediumScreen {
                    margin: 0 1rem;
                }

                .heading {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 2rem;
                    padding: .5rem 0;
                    border-bottom: 2px solid $base_color;
                }

                .cardWrapper {
                    display: flex;
                    place-items: center;
                    place-content: center;
                  

                    @include breakpoint(md) {}

                    @include mediumScreen {}

                    @include extraMediumScreen {}

                    @include LargeScreen {}
                }
            }
        }

    }
}