@import '../../../StyleHelpers/Variables.scss';

.page_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .main {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            margin-top: 120px;
        }

        @include mediumScreen {
            margin-top: 120px;
        }

        @include LargeScreen {
            margin-top: 80px;
        }

        .container {
            min-height: 510px;
            display: flex;
            justify-content: center;
            padding: 0 3rem;

            @include breakpoint(md) {
                flex-direction: column;
                padding: 0 1rem;
            }

            @include mediumScreen {
                flex-direction: column;
                padding: 0 2rem;
            }

            .left {
                flex: 2;
            }

            .right {
                flex: 6;
            }
        }
    }
}