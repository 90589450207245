@import "../../../StyleHelpers/Variables.scss";

.page_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .main {
    margin-top: 80px;
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      margin-top: 120px;
    }

    @include mediumScreen {
      margin-top: 120px;
    }

    @include LargeScreen {
      margin-top: 80px;
    }

    .productwrapper {
      padding: 0.5rem 0;
      min-height: 35rem;
      background-color: $base-color3;

      .container {
        margin: 0 4rem;
        display: block;

        @include breakpoint(md) {
          margin: 0 0;
          padding: 0 10px;
        }

        @include mediumScreen {
          margin: 0 1.5rem;
        }

        @include LargeScreen {
          margin: 0 3rem;
        }

        .heading {
          display: flex;
          align-items: center;
          justify-content:center;
          margin-bottom: 2rem;
          padding: 0.5rem 0;
          border-bottom: 2px solid $base_color;
        }

        .Wrapper {
          display: flex;
          gap: 20px;

          @include breakpoint(md) {
            flex-direction: column-reverse;
          }

          @include mediumScreen {
            flex-direction: column-reverse;
          }

          @include LargeScreen {
          }

          .Left_container {
            display: flex;
            flex-direction: column;
            width: 25%;
            padding-right: 25px;
            border-right: 1px solid $base-color;

            @include breakpoint(md) {
              width: 100%;
              border-top: 2px solid $base-color;
              padding: 0;
              border-right: none;
            }

            @include mediumScreen {
              width: 100%;
              border-top: 2px solid $base-color;
              padding: 10px 0;
              border-right: none;
            }

            @include LargeScreen {
              width: 25%;
            }
          }

          .right_parentcontainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .rightside_container {
              display: flex;
              flex-direction: column;
              gap: 20;

              .top_picks {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90px;
                height: 50px;
                background-color: $primary-color;
                border-radius: 5px;
                padding: 2px;
                -webkit-box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                -moz-box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                margin-bottom: 8px;
                margin-inline-start:35px;
                color: white;
                font-size: 13px;
                font-weight:600;

                @include breakpoint(md) {
                  width: 80px;
                  height: 23px;
                  padding: 2px;
                  font-size: 10px;
                }

                p {
                  font-size: 5px; // Adjust font size for a smaller sub-heading
                  font-weight: bold; // Set font weight to normal or as needed
                  color: white; // Set text color
                  margin: 0; // Remove default margin
                  padding: 2px 0; // Adjust padding for spacing
                  text-align: left;
                }
              }

              .popSub {
                width: 100%;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                min-width: 900px;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: start;
                gap: 10px;
                align-items: center;
                background-color: $base-color3;
                height: 50px;
                margin-bottom: 10px;
                -webkit-box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                -moz-box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                color: black;
                margin-bottom: 40px;
                padding: 32px;
                cursor: pointer;

              .notSelected{
                min-width: 200px;
                max-width: 200px;
                max-height: 50px;
                overflow: hidden;
                  padding-inline:10px ;
                  padding-block: 12px;
                  background: $gradient_color;
                  color:white;
                  border-radius: 7px;
                  -webkit-user-select: none; /* Safari */
                  -ms-user-select: none; /* IE 10 and IE 11 */
                  user-select: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size:11px;
                  font-weight:bold;

                  @include breakpoint(md) {
                    display:flex;
                    flex-direction:column;
                    min-width: 90%;
                    margin-bottom:14px;
                  }

                }

                .selected{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  min-width: 200px;
                  padding-inline:10px ;
                  padding-block: 14px;
                  max-width: 200px;
                  overflow: hidden;
                  background-color: green;
                  color: white;
                  border-radius: 5px;
                  -webkit-user-select: none; /* Safari */
                  -ms-user-select: none; /* IE 10 and IE 11 */
                  user-select: none;
                  font-size:11px;
                  font-weight:600;
                  
                  @include breakpoint(md) {
                    display:flex;
                    flex-direction:column;
                    min-width: 90%;
                    margin-bottom:14px;
                  }
                }

           

                @include breakpoint(md) {
                  min-width:0%;
                  flex-direction: column;
                  justify-content: start;
                  align-items: start;
                  height: auto;
                  gap: 15;
                  -webkit-box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                  -moz-box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                  box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                  color: black;
                  padding: 10px;
                  border-bottom: 1px solid rgba(26, 25, 25, 0.308);
                p{
                  width: 70%;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.289);
                  display: flex;
                  justify-content: start;
                  padding: 15px;

                  
                }

                }

                @include mediumScreen {
                  min-width:500px;
                  flex-direction: column;
                  justify-content: start;
                  align-items: start;
                  height: auto;
                  gap: 15;
                  -webkit-box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                  -moz-box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                  box-shadow: 1px 7px 5px -3px rgba(194, 183, 194, 1);
                  color: black;
                  padding: 10px;
                  border-bottom: 1px solid rgba(26, 25, 25, 0.308);
                p{
                  width: 70%;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.289);
                  display: flex;
                  justify-content: start;
                  padding: 15px;
                }
                }

                .popSubItem {
                  font-size: medium;
                  padding: 20px;
                  cursor: pointer;
                }
              }

              .Right_container {
                display: flex;
                width: 75%;
                min-height: 100%;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                gap: 20px;

                @include breakpoint(md) {
                  width: 100%;
                  padding: 0;
                }

                @include mediumScreen {
                  width: 100%;
                  padding: 0;
                }

                @include LargeScreen {
                  width: 75%;
                }

                .error_container {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;

                  img {
                    width: 350px;
                    height: 350px;
                    object-fit: contain;
                  }

                  h1 {
                    color: $base-color5;
                  }
                }

                .card_container {
                  width: 100%;
                  position: relative;
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr 1fr;
                  place-items: center;
                  gap: 10px;
                  margin-bottom: 1rem;

                  @include breakpoint(md) {
                    grid-template-columns: 1fr 1fr;
                  }

                  @include mediumScreen {
                    grid-template-columns: 1fr 1fr 1fr;
                  }

                  @include LargeScreen {
                    grid-template-columns: 1fr 1fr 1fr;
                  }
                }
              }
            }

            .pagination {
              .loadbtn {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                gap: 20px;
                padding: 1rem 0;

                .inactiveBtn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  color: $base_color5;
                  background-color: transparent;
                  padding: 10px;
                  font-size: 1.2rem;
                  font-weight: 700;
                  line-height: 1.2;
                  border-radius: 5px;
                  border: none;
                  cursor: pointer;

                  &:hover {
                    color: $base_color5;
                  }
                }

                .activeBtn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  color: $base_color5;
                  background-color: transparent;
                  padding: 10px;
                  font-size: 1.2rem;
                  font-weight: 700;
                  line-height: 1.2;
                  border-radius: 5px;
                  border: none;
                  cursor: pointer;

                  &:hover {
                    color: $primary_color;
                  }

                  .icon {
                    font-size: 20px;
                    font-weight: 900;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
