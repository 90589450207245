@import '../../../StyleHelpers/Variables.scss';


.left {
  flex: 1;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  border-radius: 5px;
  position: relative;

  .editButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 20px;
    font-size: 12px;
    color: #fff;
    background-color: $primary_color;
    cursor: pointer;
    border-radius: 0px 0px 0px 5px;
  }

  .title {
    font-size: 20px;
    color: lightgray;
    margin-bottom: 20px;
  }

  .item {
    display: flex;
    gap: 30px;
    padding: 20px 0;

    .itemImg {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;



      img {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        // object-fit: cover;
      }
    }



    .details {
      flex: 6;

      .itemTitle {
        margin-bottom: 10px;
        color: #555;
      }

      .detailItem {
        margin-bottom: 10px;
        font-size: 14px;

        .itemKey {
          font-weight: bold;
          color: gray;
          margin-right: 5px;
        }

        .itemValue {
          font-weight: 300;
        }

      }
    }
  }
}