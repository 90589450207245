.home {
  display: flex;
}
.home .homeContainer {
  flex: 6;
  background-color: #fff;
}
.home .widgets,
.home .charts {
  display: flex;
  padding: 20px;
  gap: 20px;
}
.home .charts {
  padding: 5px 20px;
}
.home .listContainer {
  padding: 20px;
  margin: 20px;
  border-radius: 4px;
  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.57);
}
.home .listContainer .listTitle {
  font-weight: 500;
  margin-bottom: 20px;
}/*# sourceMappingURL=Style.module.css.map */