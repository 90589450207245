.list {
  display: flex;
  flex: 2;
}
.list .listContainer {
  flex: 6;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}
.list .cellAction {
  display: flex;
  align-items: center;
  gap: 12px;
}
.list .viewButton, .list .editButton, .list .deleteButton {
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}
.list .viewButton:hover, .list .editButton:hover, .list .deleteButton:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.list .viewButton {
  color: green;
  border: 1px solid green;
}
.list .viewButton:hover {
  background-color: green;
  color: #fff;
}
.list .editButton {
  color: darkblue;
  border: 1px solid rgba(0, 0, 139, 0.596);
}
.list .editButton:hover {
  background-color: darkblue;
  color: #fff;
}
.list .deleteButton {
  color: crimson;
  border: 1px solid rgba(220, 20, 60, 0.6);
}
.list .deleteButton:hover {
  background-color: crimson;
  color: #fff;
}
.list .SquareImage {
  margin: 5px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 50px;
  height: 50px;
}
.list .RoundedImage {
  border-radius: 100%;
  margin: 5px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 50px;
  height: 50px;
}
.list .optionsContainer {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}
.list .viewButton, .list .editButton, .list .deleteButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 40px;
}
.list .confirmButton {
  padding: 10px 20px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}
.list .confirmButton:hover {
  background-color: darkgreen;
}
.list .cancelButton {
  padding: 10px 20px;
  background-color: crimson;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}
.list .cancelButton:hover {
  background-color: darkred;
}
.list .link {
  text-decoration: none;
  color: green;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid green;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  height: 27px;
}
.list .delete {
  text-decoration: none;
  color: rgb(237, 79, 79);
  font-size: 16px;
  font-weight: 400;
  border: 1px solid red;
  padding: 15px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 5px;
  cursor: pointer;
  height: 27px;
}
.list .title {
  width: 100%;
  font-size: 24px;
  color: gray;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}/*# sourceMappingURL=Style.module.css.map */