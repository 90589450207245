@import '../../../StyleHelpers/Variables.scss';


/* wishlistcard styling */

.container {
    width: 100%;
    //margin: 0 4rem;
    display: block;
    //padding: 0 20px;


    @include breakpoint(md) {
        margin: 0;
    }

    @include mediumScreen {
        margin: 0 1rem;
    }

    @include LargeScreen {
        margin: 0 2rem;
    }

    .wishlist_table {
        width: 100%;
        color: #777;
        margin-bottom: 3rem;

        thead {
            border-top: none;
            border-bottom: .1rem solid #ebebeb;

            th {
                text-align: start;
            }

            @include breakpoint(md) {
                display: none;
            }

        }

        th,
        td {
            padding-left: 0;
            padding-right: 0;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            border-top: none;
            border-bottom: .1rem solid #ebebeb;

        }

        td {
            vertical-align: middle;
            padding-top: 1rem;
            padding-bottom: 1rem;

            @include breakpoint(md) {
                border-bottom: none;
            }
        }

        tbody {
            width: 100%;

            tr {
                width: 100%;

                @include breakpoint(md) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-bottom: .1rem solid #ebebeb;
                    position: relative;
                }

                .prod_col {
                    width: 60%;

                    @include breakpoint(md) {
                        width: 100%;
                    }

                    .product {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        margin: 0;
                        padding-right: 2rem;
                        box-shadow: none;

                        @include breakpoint(md) {
                            padding-right: 0;
                        }

                        .product_media {
                            display: flex;
                            flex: 1;
                            margin: 0;
                            margin-right: 1rem;

                            img {
                                width: 100px;
                                height: 100px;
                                object-fit: cover;

                                @include breakpoint(md) {
                                    width: 80px;
                                    height: 80px;
                                }
                            }
                        }

                        .product_title {
                            display: flex;
                            flex: 2;

                            h3 {
                                color: #333333;
                                font-weight: 400;
                                font-size: 1.4rem;
                                line-height: 1.25;
                                margin: 0;

                                @include breakpoint(md) {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }

                .price_col {
                    width: 20%;
                    color: #333333;
                    font-weight: 400;
                    font-size: 1.2rem;
                }

                .remove_col {
                    width: 20%;
                    padding-right: 0;
                    padding-left: 0;
                    text-align: center;

                    @include breakpoint(md) {
                        position: absolute;
                        right: .5rem;
                        top: 0;
                        padding: .5rem !important;
                        width: auto !important;
                    }

                    button {
                        background-color: transparent;
                        border: none;
                        outline: none;
                        font-weight: 400;
                        font-size: 1.4rem;
                        color: $primary_color;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}


/* End wishlistcard styling */