.sidebar {
  flex: 1.5;
  min-height: 100vh;
  border-right: 2px solid #f8f8f8;
}
@media (max-width: 550px) {
  .sidebar {
    flex: 2.5;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .sidebar {
    flex: 2.5;
  }
}
.sidebar .logo_navigation {
  text-decoration: none;
}
.sidebar .navigation {
  text-decoration: none;
  color: #111;
  background-color: #111;
}
.sidebar .top {
  height: 70px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar .top .logo {
  font-size: 26px;
  font-weight: bold;
  color: #046BD2;
}
.sidebar hr {
  height: 0;
  border: 1px solid #f8f8f8;
}
.sidebar .center {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sidebar .center ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.sidebar .center ul .active {
  width: 100%;
  color: #fff;
  border-radius: 3px;
  background: rgba(63, 131, 209, 0.9450980392);
}
.sidebar .center ul li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  cursor: pointer;
}
.sidebar .center ul li:hover {
  width: 100%;
  background-color: #f8f8f8;
  color: #111;
  border-radius: 3px;
}
.sidebar .center ul li .active {
  width: 100%;
  color: #fff;
  border-radius: 3px;
  background: rgba(63, 131, 209, 0.9450980392);
}
.sidebar .center ul li .icons {
  font-size: 18px;
  padding-left: 10px;
}
.sidebar .center ul li span {
  font-size: 15px;
  font-weight: 400;
  margin-left: 5px;
}
.sidebar .bottom {
  display: flex;
  align-items: center;
  padding: 10px;
}
.sidebar .bottom .color_option {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #373737;
  cursor: pointer;
  margin: 5px;
}
.sidebar .bottom .color_option:nth-child(1) {
  background-color: #fff;
}
.sidebar .bottom .color_option:nth-child(2) {
  background-color: #111;
}/*# sourceMappingURL=Style.module.css.map */