.modalOverlay {
  background-color: white;
}
.modalOverlay .modalContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 750px;
  height: 450px;
  border-radius: 15px;
  padding: 20px 59px;
  position: relative;
  background-color: white;
}
@media (max-width: 550px) {
  .modalOverlay .modalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 75vw;
    height: auto;
    max-height: 80vh;
    padding: 45px;
  }
}
.modalOverlay .modalContent .closeIconWrapper {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 20px; /* Correct positioning with px */
  right: 20px; /* Correct positioning with px */
}
.modalOverlay .modalContent .closeIconWrapper .closeButton {
  background: transparent;
  border: none;
  font-weight: 100;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  width: 10px;
}
.modalOverlay .modalContent .refferimage {
  width: 450px;
}
@media (max-width: 550px) {
  .modalOverlay .modalContent .refferimage {
    width: 220px;
  }
}
.modalOverlay .modalContent p {
  font-size: medium;
  font-size: 16px;
}
@media (max-width: 550px) {
  .modalOverlay .modalContent p {
    font-size: 14px;
  }
}
.modalOverlay .modalContent .referralButton {
  background-color: #046BD2;
  color: white;
  padding: 12px 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin-inline: auto;
}
@media (max-width: 550px) {
  .modalOverlay .modalContent .referralButton {
    display: flex;
    justify-content: center;
  }
}
.modalOverlay .modalContent .referralButton:hover {
  background-color: red;
}

.item_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.item_wrap .wrapper {
  width: 700px;
  min-height: 500px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 10px;
  border: 3px solid lightgrey;
  margin: 10px;
  padding: 20px;
}
@media (max-width: 550px) {
  .item_wrap .wrapper {
    padding: 20px;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  .item_wrap .wrapper {
    padding: 20px;
  }
}
.item_wrap .wrapper .top {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 2px solid lightgrey;
}
.item_wrap .wrapper .top .row1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.item_wrap .wrapper .top .row1 h3 {
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 550px) {
  .item_wrap .wrapper .top .row1 h3 {
    font-size: 14px;
  }
}
.item_wrap .wrapper .top .row1 h5 {
  padding: 5px 10px;
  background-color: #046BD2;
  border-radius: 25px;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}
@media (max-width: 550px) {
  .item_wrap .wrapper .top .row1 h5 {
    font-size: 10px;
  }
}
.item_wrap .wrapper .top .row2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
  padding: 0 10px;
}
.item_wrap .wrapper .top .row2 h1 {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 38px;
  color: #046BD2;
}
@media (max-width: 550px) {
  .item_wrap .wrapper .top .row2 h1 {
    font-size: 28px;
  }
}
.item_wrap .wrapper .top .row2 span {
  font-size: 38px;
  font-weight: 900;
  margin: 0 5px;
}
@media (max-width: 550px) {
  .item_wrap .wrapper .top .row2 span {
    font-size: 28px;
  }
}
.item_wrap .wrapper .top .row2 h4 {
  font-size: 12px;
  color: #373737;
}
@media (max-width: 550px) {
  .item_wrap .wrapper .top .row2 h4 {
    font-size: 10px;
  }
}
.item_wrap .wrapper .top .row3 {
  padding: 5px 10px;
}
.item_wrap .wrapper .top .row3 p {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 25px;
  background-color: #f8f8f8;
  width: -moz-fit-content;
  width: fit-content;
}
.item_wrap .wrapper .top .row4 {
  margin: 20px 0;
}
.item_wrap .wrapper .top .row4 button {
  width: 100%;
  padding: 10px 0;
  background: linear-gradient(142deg, rgb(3, 116, 208) 10%, rgb(0, 239, 189) 100%);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
}
.item_wrap .wrapper .bottom {
  min-height: 100px;
  display: flex;
  flex-direction: column;
}
.item_wrap .wrapper .bottom .title {
  margin: 10px 0;
}
.item_wrap .wrapper .bottom .title h2 {
  font-size: 16px;
}
.item_wrap .wrapper .bottom .row {
  display: flex;
  flex-direction: column;
}
.item_wrap .wrapper .bottom .row .item {
  display: flex;
  align-items: center;
  gap: 20px;
  border-top: 1px solid lightgrey;
  padding: 10px;
}
.item_wrap .wrapper .bottom .row .item span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #046BD2;
  border: 2px solid #046BD2;
  border-radius: 50%;
}
.item_wrap .wrapper .bottom .row .item p {
  font-size: 14px;
}

/*----- control btn -------*/
.control_btn .next,
.control_btn .prev {
  position: absolute;
  top: 45%;
  width: 35px;
  height: 50px;
  line-height: 60px;
  background: rgba(255, 255, 255, 0.2117647059);
  border-radius: 5px;
  border: none;
  transform: 0.5s;
  z-index: 5;
}
.control_btn .next i,
.control_btn .prev i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  transition: 0.5s;
  color: #111;
  cursor: pointer;
}
.control_btn .next:hover,
.control_btn .prev:hover {
  cursor: pointer;
}
.control_btn .next:hover i,
.control_btn .prev:hover i {
  color: #111;
}
.control_btn .prev {
  left: -20px;
}
.control_btn .next {
  right: -20px;
}

/*----- control btn -------*//*# sourceMappingURL=index.module.css.map */