.Header {
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 2px solid #f8f8f8;
}
.Header .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 40px;
  gap: 10px;
}
.Header .wrapper .search {
  display: flex;
  align-items: center;
  width: 300px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 5px;
}
.Header .wrapper .search input {
  width: 100%;
  height: 2rem;
  padding: 0 5px;
  background: transparent;
  border: none;
  outline: none;
}
.Header .wrapper .search input::-moz-placeholder {
  font-size: 12px;
}
.Header .wrapper .search input::placeholder {
  font-size: 12px;
}
.Header .wrapper .items {
  display: flex;
  align-items: center;
  gap: 10px;
}
.Header .wrapper .items .item {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.Header .wrapper .items .item .icon {
  font-size: 24px;
}
.Header .wrapper .items .item .avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.Header .wrapper .items .item .counter {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: -5px;
  right: -5px;
}/*# sourceMappingURL=Style.module.css.map */